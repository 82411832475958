import { combineReducers } from 'redux';
import CONST from './Const';

const DASHBOARD_TYPE = {
  install: 'INSTALL',
  event: 'EVENT',
  reengagement_click: 'RE_CLICK',
  reengagement_event: 'RE_EVENT',
  click_impression: 'CLICK_IMPRESSION',
};

function DashboardReducer(type, init = {}) {
  return function(state = init, action) {
    switch (action.type) {
      case CONST.DASHBOARD[type][action.id]?.INIT: {
        const data = { loading: true, data: null, error: null };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      case CONST.DASHBOARD[type][action.id]?.SUCCESS: {
        const data = { loading: false, data: action.data, error: null };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      case CONST.DASHBOARD[type][action.id]?.ERROR: {
        const data = { loading: false, data: null, error: action.error };
        const newState = { ...state };
        newState[action.id] = data;
        return newState;
      }
      default:
        return state;
    }
  };
}

function FilterReducer(state = {}, action) {
  switch (action.type) {
    case CONST.FILTER[action.id]?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case CONST.FILTER[action.id]?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case CONST.FILTER[action.id]?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}

function PublisherReportReducer(state = {}, action) {
  return Reducer(CONST.PUBLISHER_REPORT[action.id], state, action);
}

function IncentDetailReducer(state = {}, action) {
  return Reducer(CONST.INCENT_DETAILS[action.id], state, action);
}

function PublisherSummaryReducer(state = {}, action) {
  return Reducer(CONST.PUBLISHER_SUMMARY[action.id], state, action);
}

function AlertPageReducer(state = {}, action) {
  return Reducer(CONST.ALERT_PAGE[action.id], state, action);
}

function PublisherReportDropDownReducer(state = {}, action) {
  return Reducer(CONST.PUBLISHER_REPORT.DROPDOWN[action.id], state, action);
}

function AppReportInstallReducer(state = {}, action) {
  return Reducer(CONST.REPORT.INSTALL[action.id], state, action);
}

function AppReportEventReducer(state = {}, action) {
  return Reducer(CONST.REPORT.EVENT[action.id], state, action);
}
function WhiteListReducer(state = {}, action) {
  return Reducer(CONST.WHITELIST[action.id], state, action);
}
function MetaCampaignConfiguration(state = {}, action) {
  return Reducer(CONST.META_CAMPAIGN_CONFIGURATION[action.id], state, action);
}

export default combineReducers({
  InstallDashboard: DashboardReducer(DASHBOARD_TYPE.install),
  EventDashboard: DashboardReducer(DASHBOARD_TYPE.event),
  ReengagementClick: DashboardReducer(DASHBOARD_TYPE.reengagement_click),
  ReengagementEvent: DashboardReducer(DASHBOARD_TYPE.reengagement_event),
  ClickImpression: DashboardReducer(DASHBOARD_TYPE.click_impression),
  PublisherSummary: PublisherSummaryReducer,
  Filters: FilterReducer,
  PublisherReport: PublisherReportReducer,
  IncentDetail: IncentDetailReducer,
  AlertPage: AlertPageReducer,
  PublisherReportDropdown: PublisherReportDropDownReducer,
  AppInstallReport: AppReportInstallReducer,
  AppEventReport: AppReportEventReducer,
  WhiteList: WhiteListReducer,
  metacampaign: MetaCampaignConfiguration,
});

function Reducer(key, state, action) {
  switch (action.type) {
    case key?.INIT: {
      const data = { loading: true, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.SUCCESS: {
      const data = { loading: false, data: action.data, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.ERROR: {
      const data = { loading: false, data: null, error: action.error };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    case key?.CLEAR: {
      const data = { loading: false, data: null, error: null };
      const newState = { ...state };
      newState[action.id] = data;
      return newState;
    }
    default:
      return state;
  }
}

import React, { useState } from "react";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import "./index.css";
import toast, { Toaster } from "react-hot-toast";
import { DNSLIST } from "../../Services/dns";
import PageLoader from "../../app/PageLoader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@material-ui/core";
import CloseButton from "react-bootstrap/CloseButton";

const BrandHygen = () => {
  const [toastId, setToastId] = useState(null);
  const [fieldValueImage, setFieldValueImage] = useState({});
  const [fieldValueVideo, setFieldValueVideo] = useState({});
  const [labelsNameArrayValues, setLabelsNameArrayValues] = useState([]);
  const [imageName, setImageName] = useState("");
  const [videoName, setVideoName] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileTypeImage, setFileTypeImage] = useState("");
  const [fileTypeVideo, setFileTypeVideo] = useState("");
  const [fullfileType, setFullFileType] = useState("");
  const [displayValues, setDisplayValues] = useState({});
  const [displayValuesImage, setDisplayValuesImage] = useState({});
  const [imageSize, setImageSize] = useState("");
  const [loading, setLoading] = useState({
    image: false,
    video: false,
  });
  const [submitImage, setSubmitImage] = useState(false);
  const [submitVideo, setSubmitVideo] = useState(false);
  const [videoDuration, setVideoDuration] = useState();
  const [disabled, setDisabled] = useState({
    image: false,
    video: false,
  });
  const [result, setResult] = useState({
    image: "",
    video: "",
  });
  const [imageLimit, setImageLimit] = useState(false);
  const [videoLimit, setVideoLimit] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: null,
  });
  const [resultCodeImage, setResultCodeImage] = useState(null);
  const [resultCodeVideo, setResultCodeVideo] = useState(null);

  const [image, setImage] = useState({
    generalLabels: {},
    celebrity: {},
    age: [],
    gender: [],
    emotions: [],
    logo: {},
    moderationLabels: {},
    text: "",
    demographics: {},
    translation: "",
  });

  const [video, setVideo] = useState({
    generalLabels: {},
    celebrity: {},
    age: [],
    gender: [],
    emotions: [],
    logo: {},
    moderationLabels: {},
    demographics: {},
    text: "",
    translation: "",
  });

  function submitImageVideo(typeOfFile) {
    if (typeOfFile == "image" && !fieldValueImage) {
      showToast("Please upload image file");
      return;
    } else if (typeOfFile == "image" && imageSize > 5) {
      showToast("Size is too large. upload a image less than 5mb!");
      return;
    } else if (typeOfFile == "video" && !fieldValueVideo) {
      showToast("Please upload video file");
      return;
    } else if (typeOfFile === "video" && videoDuration > 40) {
      showToast("Please upload a video file duration less than 40 seconds");
      return;
    } else if (typeOfFile != "video" && typeOfFile != "image") {
      showToast("Please upload valid file");
      return;
    }

    if (typeOfFile === "image") {
      setDisabled((prevState) => ({ ...prevState, image: true }));
      if (!typeOfFile.includes("image")) {
        setFieldValueImage({});
        showToast("Please upload a image file");
        return;
      }
      setLoading((prevState) => ({ ...prevState, image: true }));

      const myHeaders = new Headers();

      myHeaders.append(
        "content-disposition",
        `attachment; filename=\"${Date.now() + imageName}\"`
      );
      myHeaders.append("Content-Type", fullfileType);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fieldValueImage,
        redirect: "follow",
      };

      let toastID = toast.loading("File is getting scanned.Please wait!", {
        icon: <CircularProgress size={24} />,
      });

      async function callFirstAPI() {
        setSubmitImage(true);
        let result = await fetch(DNSLIST.BRANDHYGENE.BASE_URL, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            return result?.signedURL;
          })
          .catch((error) => console.error(error));

        setResult((prevState) => ({ ...prevState, image: result }));
        if (result) {
          let body = {
            endpoint: "generic_all",
            authorization: DNSLIST.BRANDHYGENE.AUTHORIZATION,
            s3_presigned_url: result,
          };


          let timeoutId;

          function callSecondAPI() {

            timeoutId = setTimeout(() => {
              toast.dismiss(toastID);
              showToast("Unable to process currently. Try again in a few minutes");
              setDisabled((prevState) => ({ ...prevState, image: false }));
              setLoading((prevState) => ({ ...prevState, image: false }));
              return;
            },150000);

            fetch(
              DNSLIST.BRANDHYGENE.SAFETY,
              {
                Accept: "*/*",
                "Content-Type": "application/json",
                method: "POST",
                body: JSON.stringify(body),
                redirect: "follow",
              }
            )
              .then((response) => {
                clearTimeout(timeoutId);
                return response.json()
              })
              .then((result) => {
                setResultCodeImage(result?.statusCode);
                if (result?.statusCode === 429) {
                  setImageLimit(result?.body);
                  showToast(result?.body);
                  return;
                }
                if (result?.statusCode === 500) {
                  setImageLimit(result?.body);
                  showToast(result?.body);
                  return;
                }
                if (result?.statusCode === 422) {
                  setImageLimit(result?.body);
                  showToast(result?.body);
                  return;
                }
                let finalResult = result?.body?.result;

                setImage((prevState) => ({
                  ...prevState,
                  celebrity: finalResult?.celebrity || {},
                  age: finalResult?.demographics?.age || [],
                  gender: finalResult?.demographics?.gender || [],
                  emotions: finalResult?.demographics?.emotions || [],
                  logo: finalResult?.logo || {},
                  moderationLabels: finalResult?.moderation_labels || {},
                  text: finalResult?.text || "",
                  translation: finalResult?.translation || "",
                  generalLabels: finalResult?.generic_labels || {},
                  demographics: finalResult?.demographics || {},
                }));
              })
              .catch((error) => {
                showToast("Something went wrong!");
                return;
              })
              .finally(() => {
                setDisabled((prevState) => ({ ...prevState, image: false }));
                setLoading((prevState) => ({ ...prevState, image: false }));
                toast.dismiss(toastID);
              });
          }

          callSecondAPI();
        }
      }

      callFirstAPI();
    } else if (typeOfFile === "video") {
      setDisabled((prevState) => ({ ...prevState, video: true }));
      if (!typeOfFile.includes("video")) {
        setFieldValueVideo({});
        showToast("Please upload a video file");
        return;
      }
      setLoading((prevState) => ({ ...prevState, video: true }));
      const reader = new FileReader();

      const myHeaders = new Headers();
      myHeaders.append(
        "content-disposition",
        `attachment; filename=\"${Date.now() + videoName}\"`
      );
      myHeaders.append("Content-Type", fullfileType);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: fieldValueVideo,
        redirect: "follow",
      };

      let toastID = toast.loading("File is getting scanned.Please wait!", {
        icon: <CircularProgress size={24} />,
      });

      async function callFirstAPI() {
        setSubmitVideo(true);
        let videoURL = await fetch(DNSLIST.BRANDHYGENE.BASE_URL, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            return result?.signedURL;
          })
          .catch((error) => console.error(error));

        setResult((prevState) => ({ ...prevState, video: videoURL }));
        if (videoURL) {
          let body = {
            endpoint: "generic_all",
            authorization: DNSLIST.BRANDHYGENE.AUTHORIZATION,
            s3_presigned_url: videoURL,
          };

          let timeoutId;

          async function callSecondAPI() {

            timeoutId = setTimeout(() => {
              toast.dismiss(toastID);
              showToast("Unable to process currently. Try again in a few minutes");
              setDisabled((prevState) => ({ ...prevState, video: false }));
              setLoading((prevState) => ({ ...prevState, video: false }));
              return;
            } ,150000);

            fetch(DNSLIST.BRANDHYGENE.SAFETY, {
              Accept: "*/*",
              "Content-Type": "application/json",
              method: "POST",
              body: JSON.stringify(body),
              redirect: "follow",
            })
              .then((response) => {
                clearTimeout(timeoutId);
                return response.json()
              })
              .then((result) => {
                setResultCodeVideo(result?.statusCode);
                if (result?.statusCode === 429) {
                  setVideoLimit(result?.body);
                  showToast(result?.body);
                  return;
                }
                if (result?.statusCode === 500) {
                  setVideoLimit(result?.body);
                  showToast(result?.body);
                  return;
                }
                if (result?.statusCode === 422) {
                  setVideoLimit(result?.body);
                  showToast(result?.body);
                  return;
                }

                let finalResult = result?.body?.result;

                setVideo((prevState) => ({
                  ...prevState,
                  celebrity: finalResult?.celebrity || {},
                  age: finalResult?.demographics?.age || [],
                  gender: finalResult?.demographics?.gender || [],
                  emotions: finalResult?.demographics?.emotions || [],
                  logo: finalResult?.logo || {},
                  moderationLabels: finalResult?.moderation_labels || {},
                  text: finalResult?.text || "",
                  translation: finalResult?.translation || "",
                  generalLabels: finalResult?.generic_labels || {},
                  demographics: finalResult?.demographics || {},
                }));
              })
              .catch((error) => {
                showToast("Something went wrong!");
                return;
              })
              .finally(() => {
                setDisabled((prevState) => ({ ...prevState, video: false }));
                setLoading((prevState) => ({ ...prevState, video: false }));
                toast.dismiss(toastID);
              });
          }

          callSecondAPI();
        }
      }

      callFirstAPI();
    }
  }

  const showToast = (errorMessage) => {
    toast.error(
      (t) => (
        <div className="d-flex">
          <span>{errorMessage}</span>
          <CloseButton
            onClick={() => toast.dismiss(t.id)}
            style={{ fontSize: "2rem" }}
          />
        </div>
      ),
      {
        autoClose: false,
        duration: Infinity,
      }
    );
  };

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Toaster position="top-right" />
      <Row className="mr-0 ml-0">
        <Col sm={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <div
            style={{
              backgroundColor: "white",
              height: "70px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <h3>Brand Hygiene</h3>
          </div>
          <Nav
            variant="pills"
            className="flex-row d-flex justify-content-center mt-3"
          >
            <Nav.Item
              style={{
                backgroundColor: "#530093",
                color: "white",
                borderRadius: "4px",
              }}
            >
              <Nav.Link eventKey="first">Image Upload</Nav.Link>
            </Nav.Item>
            <Nav.Item
              style={{
                backgroundColor: "#530093",
                color: "white",
                borderRadius: "4px",
              }}
            >
              <Nav.Link eventKey="second">Video Upload</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12} xs={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div
                className="d-flex align-items-center flex-column"
                style={{ height: "79vh" }}
              >
                <div
                  className="card d-flex justify-content-center"
                  style={{
                    height: "10%",
                    padding: "15px",
                    width: "40%",
                    marginTop: "20px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <label
                      htmlFor="file"
                      style={{ marginRight: "1rem", marginBottom: "0" }}
                    >
                      Choose Image File:
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="image/*"
                      onChange={(e) => {
                        const dismissToast = () => {
                          if (toastId !== null) {
                            toast.dismiss(toastId);
                            setToastId(null);
                          }
                        };
                        dismissToast();
                        toast.dismiss(errorMessage.duration);
                        setImage((prevState) => ({
                          ...prevState,
                          generalLabels: {},
                          celebrity: {},
                          age: [],
                          gender: [],
                          emotions: [],
                          logo: {},
                          moderationLabels: {},
                          text: "",
                          translation: "",
                          demographics: {},
                        }));

                        setResult((prevState) => ({prevState,image:""}))

                        if (
                          !e?.currentTarget?.files[0]?.type.includes("image")
                        ) {
                          setFieldValueImage({});
                          showToast("Please upload image file");
                          // toast.error();
                          return;
                        }
                        setSubmitImage(false);
                        setDisplayValuesImage({});
                        let fileDetails = e?.currentTarget?.files[0];
                        setImageName(fileDetails.name);
                        const selectedFile = fileDetails;
                        var sizeInMB = (
                          selectedFile.size /
                          (1024 * 1024)
                        ).toFixed(2);

                        setImageSize(sizeInMB);

                        if (sizeInMB > 5) {
                          showToast(
                            "Size is too large. upload a image less than 5mb!"
                          );
                          return;
                        }

                        setFileTypeImage(fileDetails?.type?.split("/")[0]);
                        setFileType(fileDetails?.type?.split("/")[1]);
                        setFullFileType(fileDetails?.type);

                        setFieldValueImage(fileDetails);
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => submitImageVideo(fileTypeImage)}
                      disabled={disabled.image}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                {loading?.image ? (
                  <PageLoader />
                ) : resultCodeImage === 200 ? (
                  <>
                    <Col lg={12} md={12} style={{ maxHeight: "60vh" }}>
                      <ul
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          margin: "auto",
                        }}
                        className="ulContainer mt-3 d-flex flex-column"
                      >
                        {result?.image && 
                         <img
                          src={result?.image}
                          height={800}
                          width={800}
                          style={{ alignSelf: "center", borderRadius: "1rem" }}
                          className="mb-3"
                        />
                        }
                        <>
                          {Object?.keys(image?.generalLabels)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="generic-labels"
                                className="font-weight-bold"
                              >
                                Generic Labels
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex">
                                  {Object?.keys(image?.generalLabels)?.join(
                                    ", "
                                  )}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(image?.celebrity)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="celebrity"
                                className="font-weight-bold"
                              >
                                Celebrity
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object.keys(image?.celebrity).join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(image?.logo)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="logo"
                                className="font-weight-bold"
                              >
                                Logo
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object.keys(image?.logo).join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(image?.moderationLabels ?? {})?.length >
                            0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="moderation-labels"
                                className="font-weight-bold"
                              >
                                Moderation Labels
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object?.keys(
                                    image?.moderationLabels ?? {}
                                  )?.join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {image?.demographics?.age?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="moderation-labels"
                                className="font-weight-bold"
                              >
                                Demographics
                              </AccordionSummary>

                              <AccordionDetails className="pt-1 pb-1">
                                {image?.demographics?.age?.length > 0 && (
                                  <div className="d-flex">
                                    <b>Age: </b>
                                    <p>
                                      &nbsp;
                                      {image?.demographics?.age.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                              <AccordionDetails className="pt-1 pb-1">
                                {image?.demographics?.gender?.length > 0 && (
                                  <div className="d-flex">
                                    <b>Gender: </b>
                                    <p>
                                      &nbsp;{" "}
                                      {image?.demographics?.gender.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                              <AccordionDetails className="pt-1 pb-1">
                                {image?.demographics?.emotions?.length > 0 && (
                                  <div className="d-flex">
                                    <b>Emotions: </b>
                                    <p>
                                      &nbsp;
                                      {image?.demographics?.emotions.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {image?.text?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="text"
                                className="font-weight-bold"
                              >
                                Text
                              </AccordionSummary>
                              <AccordionDetails>
                               <div className="d-flex flex-column" style={{whiteSpace:"pre-line"}}>
                                  {image?.text}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {image?.translation?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="translation"
                                className="font-weight-bold"
                              >
                                Translation
                              </AccordionSummary>
                              <AccordionDetails>
                              <div className="d-flex flex-column" style={{whiteSpace:"pre-line"}}>
                                  {image?.translation}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      </ul>
                    </Col>
                  </>
                ) : (
                  !loading?.image &&
                  submitImage && (
                    <div
                      style={{ height: "60vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h2>No data found</h2>
                    </div>
                  ) &&
                  !loading?.image &&
                  imageLimit && (
                    <div
                      style={{ height: "60vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h4>{imageLimit}</h4>
                    </div>
                  )
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div
                className="d-flex align-items-center flex-column"
                style={{ height: "79vh" }}
              >
                <div
                  className="card d-flex justify-content-center"
                  style={{
                    height: "10%",
                    padding: "15px",
                    width: "40%",
                    marginTop: "20px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <label
                      htmlFor="file"
                      style={{ marginRight: "1rem", marginBottom: "0" }}
                    >
                      Choose Video File:
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="video/*"
                      onChange={(e) => {
                        const dismissToast = () => {
                          if (toastId !== null) {
                            toast.dismiss(toastId);
                            setToastId(null);
                          }
                        };
                        dismissToast();
                        setVideo((prevState) => ({
                          ...prevState,
                          generalLabels: {},
                          celebrity: {},
                          age: [],
                          gender: [],
                          emotions: [],
                          logo: {},
                          moderationLabels: {},
                          text: "",
                          translation: "",
                        }));

                        setResult((prevState) => ({prevState,video:""}))
                        const videoDetails = e?.currentTarget?.files[0];
                        setVideoName(videoDetails.name);
                        if (!videoDetails?.type?.includes("video")) {
                          setFieldValueVideo({});
                          showToast("Please upload a video file");
                          return;
                        }
                        setSubmitVideo(false);
                        setLabelsNameArrayValues([]);
                        setDisplayValues({});
                        setFieldValueVideo(videoDetails);
                        setFullFileType(videoDetails?.type);
                        setFileType(videoDetails?.type?.split("/")[1]);

                        const video = document.createElement("video");
                        let errorOccurred = false;

                        video.onloadedmetadata = function() {
                          video.remove();
                          setVideoDuration(video.duration);

                          if (video?.duration > 40) {
                            errorOccurred = true;
                            showToast(
                              "Please upload a video file duration less than 40 seconds"
                            );
                          }
                        };

                        video.src = URL.createObjectURL(videoDetails);
                        document.body.appendChild(video);

                        video.onloadeddata = function() {
                          if (errorOccurred) {
                            return;
                          }

                          var sizeInMB = (
                            videoDetails.size /
                            (1024 * 1024)
                          ).toFixed(2);

                          if (sizeInMB > 50) {
                            errorOccurred = true;
                            showToast(
                              "Size is too large. Upload a video less than 50mb!"
                            );
                            return;
                          }
                          setFileTypeVideo(videoDetails?.type?.split("/")[0]);
                          setFullFileType(videoDetails?.type);

                          if (!errorOccurred) {
                          }
                        };
                      }}
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => submitImageVideo(fileTypeVideo)}
                      disabled={disabled.video}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                {loading?.video ? (
                  <PageLoader />
                ) : resultCodeVideo === 200 ? (
                  <>
                    <Col lg={12} md={12} style={{ maxHeight: "60vh" }}>
                      <ul
                        style={{
                          overflowY: "auto",
                          overflowX: "auto",
                          margin: "auto",
                        }}
                        className="ulContainer mt-3 d-flex flex-column"
                      >
                        {result?.video && 
                        <video
                        src={result?.video}
                        height={800}
                        width={800}
                        style={{ alignSelf: "center", borderRadius: "1rem" }}
                        className="mb-3"
                        controls
                      ></video>
                        
                        }
                        
                        <>
                          {Object?.keys(video?.generalLabels)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="generic-labels"
                                className="font-weight-bold"
                              >
                                Generic Labels
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex">
                                  {Object.keys(video?.generalLabels).join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(video?.celebrity)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="celebrity"
                                className="font-weight-bold"
                              >
                                Celebrity
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object.keys(video?.celebrity).join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(video?.logo)?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="logo"
                                className="font-weight-bold"
                              >
                                Logo
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object.keys(video?.logo).join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {Object?.keys(video?.moderationLabels ?? {})?.length >
                            0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="moderation-labels"
                                className="font-weight-bold"
                              >
                                Moderation Labels
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column">
                                  {Object?.keys(
                                    video?.moderationLabels ?? {}
                                  )?.join(", ")}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {video?.demographics?.age.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="moderation-labels"
                                className="font-weight-bold"
                              >
                                Demographics
                              </AccordionSummary>

                              <AccordionDetails className="pt-1 pb-1">
                                {video?.demographics?.age.length > 0 && (
                                  <div className="d-flex">
                                    <b>Age: </b>
                                    <p>
                                      &nbsp;
                                      {video?.demographics?.age.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                              <AccordionDetails className="pt-1 pb-1">
                                {video?.demographics?.gender.length > 0 && (
                                  <div className="d-flex">
                                    <b>Gender: </b>
                                    <p>
                                      &nbsp;{" "}
                                      {video?.demographics?.gender.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                              <AccordionDetails className="pt-1 pb-1">
                                {video?.demographics?.emotions.length > 0 && (
                                  <div className="d-flex">
                                    <b>Emotions: </b>
                                    <p>
                                      &nbsp;
                                      {video?.demographics?.emotions.join(", ")}
                                    </p>
                                  </div>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {video?.text?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="text"
                                className="font-weight-bold"
                              >
                                Text
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="d-flex flex-column" style={{whiteSpace:"pre-line"}}>
                                  {video?.text}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                          {video?.translation?.length > 0 && (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <i className="fa-solid fa-chevron-down"></i>
                                }
                                aria-controls="panel1-content"
                                id="translation"
                                className="font-weight-bold"
                              >
                                Translation
                              </AccordionSummary>
                              <AccordionDetails>
                              <div className="d-flex flex-column" style={{whiteSpace:"pre-line"}}>
                                  {video?.translation}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </>
                      </ul>
                    </Col>
                  </>
                ) : (
                  !loading?.video &&
                  submitVideo && (
                    <div
                      style={{ height: "60vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h2>No data found</h2>
                    </div>
                  ) &&
                  !loading?.video &&
                  videoLimit && (
                    <div
                      style={{ height: "60vh" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h4>{videoLimit}</h4>
                    </div>
                  )
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default BrandHygen;

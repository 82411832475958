import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ContentRoute } from '../../../../_metronic/layout';
import BrandNewLogin from './BrandNewLogin';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import {companyDetails} from '../../../../whiteLabel';
import './styles.css';

export function AuthPage() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 999, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const productPortalMapping = [
    'mF Sentinel+ provides cutting-edge solutions to detect brand infringement instances, phishing scams and transaction-related fraud to ensure holistic protection against ever-evolving digital threats. ',
    "mF Effcent provides robust protection against unscrupulous activities conducted by affiliates and influencer's using a brand’s assets and reduces the risk of brand guidelines violation. ",
    'mF Tickr provides automated compliance regulation across creative and content to reduce errors and maximize efficiency with brand-compliant creative assets.',
    'mF Pace provides best-in-class brand reputation protection by identifying brand-unsafe content, reducing brand abuse and maximizing visibility and trust.',
    'mF Valid8, provides optimum protection from the ever-evolving threats of ad fraud, from impressions to events, ensuring your ads are seen by only your intended audience in a geo-targeted and brand-safe environment.',
  ];
  return (
    <>
      <div className='d-flex flex-column flex-root'>
        {/*begin::Login*/}
        <div
          className='login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white'
          id='kt_login'>
          {/*begin::Aside*/}
          <div
            className='login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 responsive-login'
            style={{
              backgroundImage: 'linear-gradient(indigo,indigo,purple)',
              maxWidth: '950px',
            }}>
            {/*begin: Aside Container*/}
            <div
              className='d-flex flex-row-fluid flex-column justify-content-between'
              style={{ height: '103%' }}>
              {/* start:: Aside content */}
              <div className='flex-column-fluid d-flex flex-column justify-content-center align-items-center'>
                <div className='w-50' style={{ fontFamily: 'poppins' }}>
                  <h3
                    className='mb-5 text-white'
                    style={{ fontWeight: '900', fontSize: '33px' }}>
                    Welcome to {companyDetails?.company}!
                  </h3>
                  <Carousel
                    responsive={responsive}
                    removeArrowOnDeviceType={['tablet', 'mobile', 'web']}
                    partialVisbile
                    infinite
                    autoPlay
                    // showDots
                    arrows={false}>
                    {companyDetails?.description ? companyDetails?.description?.map((v, i) => {
                      let eachWord = v.split(' ');
                      let firstWord = eachWord.at(0);
                      let secondWord = eachWord.at(1) ? eachWord.at(1) : "";
                      let remainingWords = eachWord.slice(2).join(' ');
                      return (
                        <p
                          key={i}
                          className='font-weight-lighter text-white opacity-80'
                          style={{ fontSize: '17px' }}>
                          <span style={{ color: 'yellow', fontWeight: 'bold' }}>
                            {firstWord + secondWord + ' '}
                          </span>
                          {remainingWords}
                          <br />
                        </p>
                      );
                    }) : <></>}
                  </Carousel>
                  {companyDetails?.company === "mFilterIt" &&
                  <a
                    href='https://mfilterit.com/brand-protection-solutions'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <button
                      style={{
                        background: 'indigo',
                        borderRadius: '7px',
                        color: 'white',
                        fontWeight: 'bold',
                        border: '1px solid white',
                        paddingRight: '30px',
                        fontSize: '14px',
                      }}>
                      Our Product Suite &#8594;
                    </button>
                  </a>
}
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className='d-none flex-column-auto d-lg-flex justify-content-between mt-10'>
                <div className='d-flex'></div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className='d-flex flex-column flex-row-fluid position-relative overflow-hidden align-items-center justify-content-center'>
            <div className='d-flex flex-column flex-center mt-lg-0'>
              <Switch>
                <ContentRoute path='/' component={BrandNewLogin} />
                <Redirect from='/auth' exact={true} to='/' />
                <Redirect to='/' />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { takeLatest, put, call } from 'redux-saga/effects';
import * as constant from '../constants/CommonConstants';
import CommonServices from '../services/CommonService';
import {
  ApiUrl,
  EndPoints,
  AppInstall,
  AppFilterDomain,
  MtrackitApiUrl,
  AppFilterDomainTest,
} from '../helpers/Endpoints';
import { API } from '../setupAxios';
import { AUTH_DATA } from '../../app/utils/const';
import { getLocalStorage } from '../../app/utils/helpers';

export function* ViewPackagenameSaga() {
  try {
    const response = yield call(CommonServices.ViewPackagename);
    yield put({ type: constant.PACKAGENAME_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PACKAGENAME_INIT_ERROR, error });
  }
}

export function* ViewMenulistSaga(payload) {
  try {
    const url = MtrackitApiUrl + EndPoints.menu_list;
    const params = { package_name: payload.packageName };
    const response = yield API.get(url, { params });
    yield put({ type: constant.MENULIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.MENULIST_INIT_ERROR, error });
  }
}

export function* ViewFilterlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.filter_list;
    const params = { package_name: payload.packageName };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewFilterlist,
    //   payload.packageName
    // );
    yield put({ type: constant.FILTERLIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FILTERLIST_INIT_ERROR, error });
  }
}

export function* ViewChannellistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewChannellist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.CHANNEL_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CHANNEL_LIST_INIT_ERROR, error });
  }
}
export function* ViewCountrylistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewCountrylist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.COUNTRY_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.COUNTRY_LIST_INIT_ERROR, error });
  }
}
export function* ViewCategorylistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewCategorylist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.CATEGORY_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CATEGORY_LIST_INIT_ERROR, error });
  }
}
export function* ViewBrandlistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewBrandlist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.BRAND_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.BRAND_LIST_INIT_ERROR, error });
  }
}
export function* ViewPrioritylistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewPrioritylist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.PRIORITY_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PRIORITY_LIST_INIT_ERROR, error });
  }
}
export function* ViewPublisherlistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewPublisherlist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.PUBLISHER_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_LIST_INIT_ERROR, error });
  }
}
export function* ViewStatuslistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewStatuslist,
      payload.packageName,
      payload.fromDate,
      payload.toDate
    );
    yield put({ type: constant.STATUS_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.STATUS_LIST_INIT_ERROR, error });
  }
}

export function* ViewWebChannellistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_channel_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewWebChannellist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({ type: constant.WEB_CHANNEL_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_CHANNEL_LIST_INIT_ERROR, error });
  }
}
export function* ViewCampaignlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_campaign_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
      page: payload.campageNo,
    };
    if (payload.campsearch) params.search = payload.campsearch;
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewWebCampaignlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype,
    //   payload.campageNo,
    //   payload.campsearch
    // );
    yield put({ type: constant.WEB_CAMPAIGN_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_CAMPAIGN_LIST_INIT_ERROR, error });
  }
}
export function* ViewPublisheridlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_publisherid_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewPublisheridlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );

    yield put({ type: constant.WEB_PUBLISHER_ID_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_PUBLISHER_ID_LIST_INIT_ERROR, error });
  }
}
export function* ViewWebPublisherlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_publisher_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewWebPublisherlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );

    yield put({ type: constant.WEB_PUBLISHER_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_PUBLISHER_LIST_INIT_ERROR, error });
  }
}

export function* ViewSubPublisherlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_sub_publisher_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewSubPublisherlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );

    yield put({ type: constant.WEB_SUB_PUBLISHER_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_SUB_PUBLISHER_LIST_INIT_ERROR, error });
  }
}
export function* ViewPageidlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_page_id_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
      page: payload.pageNo,
    };
    if (payload.search) params.search = payload.search;
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewPageidlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype,
    //   payload.pageNo,
    //   payload.search
    // );

    yield put({ type: constant.WEB_PAGE_ID_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_PAGE_ID_LIST_INIT_ERROR, error });
  }
}

export function* ViewCreativelistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_creative_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewCreativelist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({ type: constant.WEB_CREATIVE_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_CREATIVE_LIST_INIT_ERROR, error });
  }
}
export function* ViewSystemDomainlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_systemdomain_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
      page: payload.pageNo,
    };
    if (payload.search) params.search = payload.search;
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewSystemDomainlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype,
    //   payload.pageNo,
    //   payload.search
    // );
    yield put({ type: constant.WEB_SYSTEM_DOMAIN_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_SYSTEM_DOMAIN_LIST_INIT_ERROR, error });
  }
}
export function* ViewPlacementIdlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_placementid_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
      page: payload.page,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewPlacementidlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({ type: constant.WEB_PLACEMENT_ID_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_PLACEMENT_ID_LIST_INIT_ERROR, error });
  }
}
export function* ViewFraudCategorylistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_fraudcategory_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewFraudCategorylist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({
      type: constant.WEB_FRAUD_CATEGORY_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.WEB_FRAUD_CATEGORY_LIST_INIT_ERROR, error });
  }
}
export function* ViewFraudSubCategorylistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_fraudsubcategory_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewFraudSubCategorylist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({
      type: constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT_ERROR, error });
  }
}
export function* ViewPlacementTypelistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_creative_list;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      dashboard_type: payload.dashboardtype,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewPlacementTypelist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({
      type: constant.WEB_PLACEMENT_TYPE_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.WEB_PLACEMENT_TYPE_LIST_INIT_ERROR, error });
  }
}

export function* ViewClickFraudCategorylistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_click_fraud_category;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewClickFraudCategorylist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({ type: constant.CLICK_FRAUD_CATEGORY_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_FRAUD_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewClickFraudSubCategorylistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_click_fraud_sub_category;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewClickFraudSubCategorylist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({
      type: constant.CLICK_FRAUD_SUB_CATEGORY_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.CLICK_FRAUD_SUB_CATEGORY_INIT_ERROR, error });
  }
}

export function* ViewClickPublisherNamelistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_click_publisher;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewClickPublisherNamelist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({ type: constant.CLICK_PUBLISHER_NAME_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_PUBLISHER_NAME_INIT_ERROR, error });
  }
}

export function* ViewClickCampaignIdlistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_click_campaign;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewClickCampaignIdlist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({ type: constant.CLICK_CAMPAIGN_ID_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_CAMPAIGN_ID_INIT_ERROR, error });
  }
}

export function* ViewClickDevicetypelistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_click_device;
    const params = {
      package_name: payload.packageName,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewClickDevicetypelist,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({ type: constant.CLICK_DEVICE_TYPE_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CLICK_DEVICE_TYPE_INIT_ERROR, error });
  }
}

export function* ViewDashboardTypelistSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.configuration_dashboard_type;
    const params = {
      package_name: payload.packageName,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewDashboard_type_list,
    //   payload.packageName,
    //   payload.fromDate,
    //   payload.toDate,
    //   payload.dashboardtype
    // );
    yield put({
      type: constant.WEB_DASHBOARD_TYPE_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.WEB_DASHBOARD_TYPE_LIST_INIT_ERROR, error });
  }
}

// ----mtrackit
export function* ViewMtrackitCampaignlistSaga(payload) {
  try {
    const url = MtrackitApiUrl + EndPoints.campaign_id;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.CAMPAIGN_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.CAMPAIGN_LIST_INIT_ERROR, error });
  }
}
export function* ViewEventtypeSaga(payload) {
  try {
    const url = MtrackitApiUrl + EndPoints.event_type;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_LIST_INIT_ERROR, error });
  }
}
export function* ViewFraudtypeSaga(payload) {
  try {
    const url = MtrackitApiUrl + EndPoints.fraud_type;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.FRAUD_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.FRAUD_LIST_INIT_ERROR, error });
  }
}
export function* ViewPublishernameSaga(payload) {
  try {
    const url = MtrackitApiUrl + EndPoints.publisher_name;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    };
    const response = yield API.get(url, { params });
    // const response = yield call(
    //   CommonServices.ViewPublishername,
    //   payload.package_name,
    //   payload.fromDate,
    //   payload.toDate
    // );
    yield put({ type: constant.PUBLISHER_NAME_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.PUBLISHER_NAME_INIT_ERROR, error });
  }
}
// event
export function* ViewEventCampaignSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_campaign_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: payload.pageNo ?? 1,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_CAMPAIGN_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_CAMPAIGN_LIST_INIT_ERROR, error });
  }
}

export function* ViewEventPageSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_page_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: payload.pageNo ?? 1,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_PAGE_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_PAGE_LIST_INIT_ERROR, error });
  }
}
export function* ViewEventPublisherSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_publisher_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: payload.pageNo ?? 1,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_PUBLISHER_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_PUBLISHER_LIST_INIT_ERROR, error });
  }
}
export function* ViewEventChannelSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_channel_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: payload.pageNo ?? 1,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_CHANNEL_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_CHANNEL_LIST_INIT_ERROR, error });
  }
}
export function* ViewEventEventSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_event_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: payload.pageNo ?? 1,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.EVENT_EVENT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.EVENT_EVENT_LIST_INIT_ERROR, error });
  }
}

// web event report saga

export function* ViewReportFeildsSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_report_feilds_list;
    const params = {
      dashboard_type: payload.dashboard_type,
    };
    const response = yield API.get(url, { params });
    yield put({
      type: constant.EVENT_REPORT_FEILDS_lIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.EVENT_REPORT_FEILDS_lIST_INIT_ERROR, error });
  }
}

export function* ViewReportFeildsSagaImpressionIntegrity(payload) {
  try {
    const url = ApiUrl + EndPoints.event_report_feilds_list;
    const params = {
      dashboard_type: payload.dashboard_type,
    };
    const response = yield API.get(url, { params });
    yield put({
      type: constant.IMPRESSION_INTEGRITY_REPORT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.IMPRESSION_INTEGRITY_REPORT_ERROR, error });
  }
}

export function* ViewReportPublisherSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_report_publisher_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: 1,
    };
    const response = yield API.get(url, { params });
    yield put({
      type: constant.EVENT_REPORT_PUBLISHER_lIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.EVENT_REPORT_PUBLISHER_lIST_INIT_ERROR, error });
  }
}

export function* ViewReportFraudCategorySaga(payload) {
  try {
    const url = ApiUrl + EndPoints.event_report_fraud_category_list;
    const params = {
      package_name: payload.package_name,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      page: 1,
    };
    const response = yield API.get(url, { params });
    yield put({
      type: constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_ERROR, error });
  }
}

// web event report saga
export function* ViewWebEventSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.web_event_report;
    const params = {
      package_name: payload.package_name,
    };
    const response = yield API.get(url, { params });
    yield put({ type: constant.WEB_EVENT_REPORT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.WEB_EVENT_REPORT_LIST_INIT_ERROR, error });
  }
}

export function* ViewAppEventSaga(payload) {
  try {
    const params = { ...payload.reportdata };
    const url = ApiUrl + EndPoints.app_event_reports;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_EVENT_REPORT_LIST_INIT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_EVENT_REPORT_LIST_INIT_ERROR, error });
  }
}

export function* ViewAppInstallSaga(payload) {
  try {
    const params = { ...payload.reportdata };
    const url = ApiUrl + EndPoints.app_install_reports;
    const response = yield API.get(url, { params });
    yield put({
      type: constant.APP_INSTALL_REPORT_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.APP_INSTALL_REPORT_LIST_INIT_ERROR, error });
  }
}

// App
export function* AppPublisherTestFIlter({ payload }) {
  try {
    let url = AppFilterDomain + '/publisher_list';
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_PUBLISHER_TEST_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_PUBLISHER_TEST_ERROR, error });
  }
}
export function* AppPublisherFIlter({ payload }) {
  try {
    const auth = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
    if (auth.publisher && auth.role === 'publisher') {
      const response = auth.publisher?.map(v => [v, 0, 0, 0]);
      yield put({ type: constant.APP_F_PUBLISHER_SUCCESS, response });
      return;
    }
    let url = AppFilterDomain + EndPoints.app_F_publisher;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_PUBLISHER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_PUBLISHER_ERROR, error });
  }
}
export function* AppCountryFIlter({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_country;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_COUNTRY_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_COUNTRY_ERROR, error });
  }
}
export function* AppCampaignFIlter({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_campaign;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_CAMPAIGN_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_CAMPAIGN_ERROR, error });
  }
}
export function* AppVendorFIlter({ payload }) {
  const auth = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
  try {
    if (auth.role === 'agency') {
      yield put({ type: constant.APP_F_VENDOR_SUCCESS, response: auth.vendor });
    } else {
      let url = AppFilterDomain + EndPoints.app_F_vendor;
      const params = payload;
      const response = yield API.get(url, { params });
      yield put({ type: constant.APP_F_VENDOR_SUCCESS, response });
    }
  } catch (error) {
    yield put({ type: constant.APP_F_VENDOR_ERROR, error });
  }
}
export function* AppEventFIlter({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_event;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_EVENT_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_EVENT_ERROR, error });
  }
}

export function* AppSubpublisherFIlter({ payload }) {
  try {
    let url = AppFilterDomain + EndPoints.app_F_sub_publisher;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_SUBPUBLISHER_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_SUBPUBLISHER_ERROR, error });
  }
}
export function* AppFieldsFIlter({ payload }) {
  try {
    let url = AppFilterDomainTest + EndPoints.app_F_fields;
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_FIELDS_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_FIELDS_ERROR, error });
  }
}
export function* AppFraudFIlter({ payload, report_type }) {
  try {
    let url =
      AppInstall + '/'+report_type+'/fraud_statistics'
    const params = payload;
    const response = yield API.get(url, { params });
    yield put({ type: constant.APP_F_FRAUD_SUCCESS, response });
  } catch (error) {
    yield put({ type: constant.APP_F_FRAUD_ERROR, error });
  }
}

export function* ViewGenerateReportSaga(payload) {
  try {
    const url = ApiUrl + EndPoints.generate_report;
    const response = yield API.post(url, payload.generatedate);
    yield put({ type: constant.EVENT_GENERATE_REPORT_INIT_SUCCESS, response });
  } catch (error) {
    yield put({
      type: constant.EVENT_GENERATE_REPORT_INIT_ERROR,
      error: '400',
    });
  }
}

// app event generate saga

export function* ViewAppEventGenerateReportSaga(payload) {
  try {
    let queryParams = payload.generatedate;
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach(key =>
      searchParams.append(key, queryParams[key])
    );

    const url =
      AppFilterDomain + EndPoints.event_generate_report + '?' + searchParams;
    const response = yield API.post(url, payload.generatedate);
    yield put({
      type: constant.APP_EVENT_GENERATE_REPORT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.APP_EVENT_GENERATE_REPORT_INIT_ERROR,
      error: '400',
    });
  }
}

export function* ViewAppInstallGenerateReportSaga(payload) {
  try {
    let queryParams = payload.generatedate;
    let searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach(key =>
      searchParams.append(key, queryParams[key])
    );
    //app
    const url =
      AppFilterDomain + EndPoints.install_generate_report + '?' + searchParams;
    const response = yield API.post(url);
    yield put({
      type: constant.INSTALL_GENERATE_REPORT_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.EVENT_GENERATE_REPORT_INIT_ERROR,
      error: '400',
    });
  }
}

// ----mtrackit Report
export function* ViewMtrackitReportCampaignlistSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewReportCampaignlist,
      payload.package_name,
      payload.fromDate,
      payload.toDate
    );
    yield put({
      type: constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT_ERROR,
      error,
    });
  }
}
export function* ViewMtrackitReportEventtypeSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewReportEventlist,
      payload.package_name,
      payload.fromDate,
      payload.toDate
    );
    yield put({
      type: constant.MTRACKIT_REPORT_EVENT_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({ type: constant.MTRACKIT_REPORT_EVENT_LIST_INIT_ERROR, error });
  }
}
export function* ViewMtrackitReportFraudtypeSaga(payload) {
  try {
    const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
    if (/publisher/i.test(getauthvalue.role) && getauthvalue.fraud_type) {
      const data = {
        data: getauthvalue?.fraud_type?.map(v => ({ fraud_status: v })) ?? [],
      };
      yield put({
        type: constant.MTRACKIT_REPORT_FRAUD_LIST_INIT_SUCCESS,
        response: data,
      });
    } else {
      const response = yield call(
        CommonServices.ViewReportFraudlist,
        payload.package_name,
        payload.fromDate,
        payload.toDate
      );
      yield put({
        type: constant.MTRACKIT_REPORT_FRAUD_LIST_INIT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({ type: constant.MTRACKIT_REPORT_FRAUD_LIST_INIT_ERROR, error });
  }
}

export function* ViewMtrackitReportPublishernameSaga(payload) {
  try {
    // publisher login start
    // decode token and check for role === 'publisher' then return publisher field from token
    const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
    if (/publisher/i.test(getauthvalue.role)) {
      const data = {
        data: getauthvalue?.publisher?.map(v => ({ publisher_name: v })) ?? [],
      };
      yield put({
        type: constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT_SUCCESS,
        response: data,
      });
    } else {
      // publisher login end
      const response = yield call(
        CommonServices.ViewReportPublishername,
        payload.package_name,
        payload.fromDate,
        payload.toDate
      );
      yield put({
        type: constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT_ERROR,
      error,
    });
  }
}

//mtrackit campaign report
export function* ViewMtrackitCampaignReportCampaignSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewCampaignReportCampaignlist,
      payload.package_name,
      payload.fromDate,
      payload.toDate
    );
    yield put({
      type: constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT_ERROR,
      error,
    });
  }
}

export function* ViewMtrackitCampaignReportPublishernameSaga(payload) {
  try {
    // publisher login start
    // decode token and check for role === 'publisher' then return publisher field from token
    const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
    if (/publisher/i.test(getauthvalue.role)) {
      const data = {
        data: getauthvalue?.publisher?.map(v => ({ publisher_name: v })) ?? [],
      };
      yield put({
        type: constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_SUCCESS,
        response: data,
      });
    } else {
      // publisher login end
      const response = yield call(
        CommonServices.ViewCampaignReportPublishername,
        payload.package_name,
        payload.fromDate,
        payload.toDate
      );
      yield put({
        type: constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_ERROR,
      error,
    });
  }
}

//mtrackit postback report

export function* ViewMtrackitPostbackReportEventSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewPostbackReportEventlist,
      payload.package_name,
      payload.fromDate,
      payload.toDate
    );
    yield put({
      type: constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT_ERROR,
      error,
    });
  }
}

export function* ViewMtrackitPostbackReportPbStatusSaga(payload) {
  try {
    const response = yield call(
      CommonServices.ViewPostbackReportPbStatuslist,
      payload.package_name,
      payload.fromDate,
      payload.toDate
    );
    yield put({
      type: constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT_ERROR,
      error,
    });
  }
}

export function* ViewMtrackitPostbackReportPublishernameSaga(payload) {
  try {
    // publisher login start
    // decode token and check for role === 'publisher' then return publisher field from token
    const getauthvalue = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
    if (/publisher/i.test(getauthvalue.role)) {
      const data = {
        data: getauthvalue?.publisher?.map(v => ({ publisher_name: v })) ?? [],
      };
      yield put({
        type: constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_SUCCESS,
        response: data,
      });
    } else {
      // publisher login end
      const response = yield call(
        CommonServices.ViewPostbackReportPublishername,
        payload.package_name,
        payload.fromDate,
        payload.toDate
      );
      yield put({
        type: constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT_SUCCESS,
        response,
      });
    }
  } catch (error) {
    yield put({
      type: constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT_ERROR,
      error,
    });
  }
}

export default function* CommonSaga() {
  yield takeLatest(constant.PACKAGENAME_INIT, ViewPackagenameSaga);
  yield takeLatest(constant.EVENT_GENERATE_REPORT_INIT, ViewGenerateReportSaga);
  yield takeLatest(
    constant.APP_EVENT_GENERATE_REPORT_INIT,
    ViewAppEventGenerateReportSaga
  );
  yield takeLatest(
    constant.INSTALL_GENERATE_REPORT_INIT,
    ViewAppInstallGenerateReportSaga
  );
  yield takeLatest(constant.WEB_EVENT_REPORT_REFETCH, ViewWebEventSaga);
  yield takeLatest(constant.APP_EVENT_REPORT_REFETCH, ViewAppEventSaga);
  yield takeLatest(constant.APP_INSTALL_REPORT_REFETCH, ViewAppInstallSaga);
  yield takeLatest(constant.MENULIST_INIT, ViewMenulistSaga);
  yield takeLatest(constant.FILTERLIST_INIT, ViewFilterlistSaga);
  yield takeLatest(constant.CHANNEL_LIST_INIT, ViewChannellistSaga);
  yield takeLatest(constant.COUNTRY_LIST_INIT, ViewCountrylistSaga);
  yield takeLatest(constant.CATEGORY_LIST_INIT, ViewCategorylistSaga);
  yield takeLatest(constant.BRAND_LIST_INIT, ViewBrandlistSaga);
  yield takeLatest(constant.PRIORITY_LIST_INIT, ViewPrioritylistSaga);
  yield takeLatest(constant.PUBLISHER_LIST_INIT, ViewPublisherlistSaga);
  yield takeLatest(constant.STATUS_LIST_INIT, ViewStatuslistSaga);
  yield takeLatest(constant.WEB_CHANNEL_LIST_INIT, ViewWebChannellistSaga);
  yield takeLatest(constant.WEB_CAMPAIGN_LIST_INIT, ViewCampaignlistSaga);
  yield takeLatest(constant.WEB_CREATIVE_LIST_INIT, ViewCreativelistSaga);
  yield takeLatest(
    constant.WEB_SYSTEM_DOMAIN_LIST_INIT,
    ViewSystemDomainlistSaga
  );
  yield takeLatest(
    constant.WEB_PLACEMENT_ID_LIST_INIT,
    ViewPlacementIdlistSaga
  );
  yield takeLatest(
    constant.WEB_FRAUD_CATEGORY_LIST_INIT,
    ViewFraudCategorylistSaga
  );
  yield takeLatest(
    constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT,
    ViewFraudSubCategorylistSaga
  );
  yield takeLatest(
    constant.WEB_PLACEMENT_TYPE_LIST_INIT,
    ViewPlacementTypelistSaga
  );
  yield takeLatest(
    constant.WEB_DASHBOARD_TYPE_LIST_INIT,
    ViewDashboardTypelistSaga
  );
  yield takeLatest(constant.WEB_EVENT_REPORT_LIST_INIT, ViewWebEventSaga);

  yield takeLatest(constant.APP_EVENT_REPORT_LIST_INIT, ViewAppEventSaga);

  yield takeLatest(constant.APP_INSTALL_REPORT_LIST_INIT, ViewAppInstallSaga);

  yield takeLatest(
    constant.EVENT_REPORT_FEILDS_lIST_INIT,
    ViewReportFeildsSaga
  );

  yield takeLatest(
    constant.EVENT_REPORT_PUBLISHER_lIST_INIT,
    ViewReportPublisherSaga
  );
  yield takeLatest(
    constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_INIT,
    ViewReportFraudCategorySaga
  );
  // yield takeLatest(constant.EVENT_DOWNLOAD_INIT, downloadEventreportSaga);

  // yield takeLatest(constant.WEB_SET_DASHBOARD_TYPE_LIST_INIT, SetDashboardType);
  yield takeLatest(
    constant.WEB_PUBLISHER_ID_LIST_INIT,
    ViewPublisheridlistSaga
  );
  yield takeLatest(constant.WEB_PUBLISHER_LIST_INIT, ViewWebPublisherlistSaga);
  yield takeLatest(
    constant.WEB_SUB_PUBLISHER_LIST_INIT,
    ViewSubPublisherlistSaga
  );
  yield takeLatest(constant.WEB_PAGE_ID_LIST_INIT, ViewPageidlistSaga);
  yield takeLatest(
    constant.CLICK_FRAUD_CATEGORY_INIT,
    ViewClickFraudCategorylistSaga
  );
  yield takeLatest(
    constant.CLICK_FRAUD_SUB_CATEGORY_INIT,
    ViewClickFraudSubCategorylistSaga
  );
  yield takeLatest(
    constant.CLICK_PUBLISHER_NAME_INIT,
    ViewClickPublisherNamelistSaga
  );
  yield takeLatest(
    constant.CLICK_CAMPAIGN_ID_INIT,
    ViewClickCampaignIdlistSaga
  );
  yield takeLatest(
    constant.CLICK_DEVICE_TYPE_INIT,
    ViewClickDevicetypelistSaga
  );
  yield takeLatest(constant.CAMPAIGN_LIST_INIT, ViewMtrackitCampaignlistSaga);
  yield takeLatest(constant.EVENT_LIST_INIT, ViewEventtypeSaga);
  yield takeLatest(constant.FRAUD_LIST_INIT, ViewFraudtypeSaga);
  yield takeLatest(constant.PUBLISHER_NAME_INIT, ViewPublishernameSaga);
  yield takeLatest(constant.EVENT_CAMPAIGN_LIST_INIT, ViewEventCampaignSaga);
  yield takeLatest(constant.EVENT_PAGE_LIST_INIT, ViewEventPageSaga);
  yield takeLatest(constant.EVENT_PUBLISHER_LIST_INIT, ViewEventPublisherSaga);
  yield takeLatest(constant.EVENT_CHANNEL_LIST_INIT, ViewEventChannelSaga);
  yield takeLatest(constant.EVENT_EVENT_LIST_INIT, ViewEventEventSaga);

  yield takeLatest(constant.APP_F_PUBLISHER_TEST_INIT, AppPublisherTestFIlter);
  yield takeLatest(constant.APP_F_PUBLISHER_TEST_NEXT, AppPublisherTestFIlter);
  yield takeLatest(constant.APP_F_PUBLISHER_INIT, AppPublisherFIlter);
  yield takeLatest(constant.APP_F_COUNTRY_INIT, AppCountryFIlter);
  yield takeLatest(constant.APP_F_CAMPAIGN_INIT, AppCampaignFIlter);
  yield takeLatest(constant.APP_F_VENDOR_INIT, AppVendorFIlter);
  yield takeLatest(constant.APP_F_EVENT_INIT, AppEventFIlter);
  yield takeLatest(constant.APP_F_SUBPUBLISHER_INIT, AppSubpublisherFIlter);
  yield takeLatest(constant.APP_F_FIELDS_INIT, AppFieldsFIlter);
  yield takeLatest(constant.APP_F_FRAUD_INIT, AppFraudFIlter);

  yield takeLatest(
    constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT,
    ViewMtrackitReportCampaignlistSaga
  );
  yield takeLatest(
    constant.MTRACKIT_REPORT_EVENT_LIST_INIT,
    ViewMtrackitReportEventtypeSaga
  );
  yield takeLatest(
    constant.MTRACKIT_REPORT_FRAUD_LIST_INIT,
    ViewMtrackitReportFraudtypeSaga
  );
  yield takeLatest(
    constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT,
    ViewMtrackitReportPublishernameSaga
  );
  yield takeLatest(
    constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT,
    ViewMtrackitCampaignReportCampaignSaga
  );
  yield takeLatest(
    constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT,
    ViewMtrackitCampaignReportPublishernameSaga
  );
  yield takeLatest(
    constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT,
    ViewMtrackitPostbackReportEventSaga
  );
  yield takeLatest(
    constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT,
    ViewMtrackitPostbackReportPbStatusSaga
  );
  yield takeLatest(
    constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT,
    ViewMtrackitPostbackReportPublishernameSaga
  );
}

import React, { useState } from "react";
import { Col, Button, Card } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress } from "@material-ui/core";

// Custom components
import PageLoader from "../../app/PageLoader";
import { uploadFile, scanFile } from "./api";
import { showToast, checkSizeInMB } from "./utils";

const AudioUpload = () => {
    const [audioResponse, setAudioResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [audioStatus, setAudioStatus] = useState(null);
    const [audioLimit, setAudioLimit] = useState("");
    const [audioDetails, setAudioDetails] = useState({});
    const [presignedUrl, setPresignedUrl] = useState("");
    const [selectedFileURL, setSelectedFileURL] = useState(null);

    const submitAudio = () => {
        setLoading(true);

        let toastID = toast.loading("File is getting scanned. Please wait!", {
            duration: 3000,
            icon: <CircularProgress size={24} />,
        });

        uploadFile(audioDetails)
            .then((presignedUrl) => {
                if (!presignedUrl) {
                    setLoading(false);
                    return;
                }

                setPresignedUrl(presignedUrl);

                // Call scanFile with the desired endpoint
                scanFile(presignedUrl, "speech_recognition")
                    .then((result) => {
                        setAudioStatus(result?.statusCode);
                        if ([429, 500, 422].includes(result?.statusCode)) {
                            setAudioLimit(result?.body);
                            showToast(result?.body);
                            return;
                        }

                        setAudioResponse(result?.body?.result || {});
                    })
                    .catch(() => {
                        showToast("Something went wrong!");
                    })
                    .finally(() => {
                        setLoading(false);
                        toast.dismiss(toastID);
                    });
            })
            .catch(() => setLoading(false));
    };

    const handleFileChange = (e) => {
        setPresignedUrl("");
        setAudioStatus(null);
        setAudioResponse({});
        setAudioDetails(e?.currentTarget?.files[0]);
        setSelectedFileURL(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <Card
            className="d-flex mb-0 flex-column flex-md-row"
            style={{ borderRadius: "1rem", padding: "1rem", height: "70vh" }}
        >
            <Col
                lg={6}
                md={6}
                style={{ maxHeight: "64vh" }}
                className="d-flex justify-content-center align-items-center"
            >
                {selectedFileURL ? (
                    <audio controls>
                        <source src={selectedFileURL} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                ) : (
                    <h3>Please upload audio file</h3>
                )}
            </Col>

            <Col lg={6} md={6}>
                <h6>
                    <b>In-Audio content detection:</b>
                    <br />
                </h6>
                <p>
                    Our tool helps you transcribe the audio, assisting you to detect the
                    presence of any harmful text in the audio.
                    <br />
                    <b>
                        Note: The audio duration must not exceed 1 minute, and the file size should be within 39 MB.
                    </b>
                </p>
                <div className="d-flex p-5 flex-column">
                    <div className="d-flex align-items-center justify-content-center">
                        <label
                            htmlFor="file"
                            style={{ marginRight: "1rem", marginBottom: "0" }}
                        >
                            Choose Audio File:
                        </label>
                        <input
                            type="file"
                            id="file"
                            name="file"
                            accept="audio/*, video/*"
                            onChange={handleFileChange}
                        />

                        <Button
                            variant="primary"
                            type="submit"
                            className="rounded"
                            style={{ width: "18rem" }}
                            onClick={submitAudio}
                            disabled={!selectedFileURL} // Disable button if no file is selected
                        >
                            Upload Audio <i className="fa-solid fa-headphones ml-2"></i>
                        </Button>
                    </div>

                    {loading ? (
                        <PageLoader />
                    ) : audioStatus === 200 ? (
                        <Col xl={12} md={12} lg={12}>
                            <Card className="gutter-b-custom mt-3">
                                <Card.Body className="pt-0 pb-0 InvoiceBody p-6 detailsCardBh">
                                    <ul
                                        style={{
                                            overflowX: "auto",
                                            margin: "auto",
                                            maxWidth: "100%",
                                            minWidth: "100%",
                                        }}
                                        className="ulContainer mt-3 d-flex flex-column"
                                    >
                                        {Object.entries(audioResponse).map(([key, value]) => {
                                            // Filter out keys with blank values
                                            if (
                                                !value ||
                                                (Array.isArray(value) && value.length === 0)
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <li key={key}>
                                                    <b>{key}:</b>{" "}
                                                    {Array.isArray(value) ? value.join(", ") : value}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : (
                        <></>
                    )}
                </div>
            </Col>
        </Card>
    );
};

export default AudioUpload;

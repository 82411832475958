import React from "react";
import "./table.scss";
import Utils from "../../../../Utils";
import NoData from "../../../../Components/Common/NoData";
import { AiOutlineDownload } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { MdModeEditOutline, MdVisibility } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import { Button, Spinner } from "react-bootstrap";

const CustomTable = ({
  data = null,
  columns = null,
  hover = true,
  striped = true,
  headerStyle,
  bodyStyle,
  cellStyle,
  isEdit = false,
  isView = false,
  isDelete = false,
  isDownload = false,
  isCancel = false,
  onEdit,
  onView,
  onDel,
  onDown = () => {},
  loading = false,
  headerTooltip = false,
}) => {
  // Function to calculate grand total for a specific column
  const calculateGrandTotal = (data, field) => {
    return Utils.numberWithCommas(
      data.reduce((acc, row) => acc + (row[field] || 0), 0)
    );
  };

  // Check if any column has total set to true
  const hasTotalColumn = columns && columns.some((col) => col.total);

  const Mapping = {
    "Total VAST Requests": {
      definition:
        "Refers to the total number of requests made for Video Ad Serving Template (VAST) ads to load from the ad manager.",
    },
    brand_unsafe_placement: {
      definition:
        "Refers to situations where ads are displayed in contexts or environments that could harm a brand's reputation.",
    },
    f_cap_threshold_breached: {
      definition:
        "Refers to violations of the frequency capping (F-CAP) limits. In this case, F-CAP is set to 3 for the duration of the campaign.",
    },
    invalid_ivt_request: {
      definition:
        "Refers to ad requests that are generated by bots. This includes categories such as Data Centres, Deprecated OS, Deprecated Browser, and Invalid Geo.",
    },
    "Total Blocked Requests": {
      definition:
        "Brand Unsafe Placement + F-CAP Threshold Breached + IVT Request",
    },
    clean_traffic_request: {
      definition: "Total VAST Request - Total Blocked Requests",
    },
    "Total Impressions": {
      definition: "Refers to the total number of times an ad is served.",
    },
  };

  return (
    <div className="tdCustomContainer">
      {loading ? (
        <table>
          <thead className="TheadFixed">
            <tr>
              {columns &&
                columns.map((head) => (
                  <>
                    <th
                      style={{ ...headerStyle }}
                      title={
                        headerTooltip && Mapping[head.header]?.definition
                          ? Mapping[head.header].definition
                          : head.header
                      }
                    >
                      {head.header}
                    </th>
                  </>
                ))}
              {isEdit || isDelete || isDownload || isCancel || isView ? (
                <th style={{ ...headerStyle }}>Action</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <Spinner animation="border" variant="warning" />
            </div>
          </tbody>
        </table>
      ) : data.length === 0 ? (
        <table>
          <thead className="TheadFixed">
            <tr>
              {columns &&
                columns.map((head) => (
                  <>
                    <th
                      style={{ ...headerStyle }}
                      title={
                        headerTooltip && Mapping[head.header]?.definition
                          ? Mapping[head.header].definition
                          : head.header
                      }
                    >
                      {head.header}
                    </th>
                  </>
                ))}
              {isEdit || isDelete || isDownload || isCancel || isView ? (
                <th style={{ ...headerStyle }}>Action</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <NoData />
            </div>
          </tbody>
        </table>
      ) : (
        <table>
          <thead className="TheadFixed">
            <tr>
              {columns &&
                columns.map((head) => (
                  <>
                    <th
                      style={{ ...headerStyle }}
                      title={
                        headerTooltip && Mapping[head.header]?.definition
                          ? Mapping[head.header].definition
                          : head.header
                      }
                    >
                      {head.header}
                    </th>
                  </>
                ))}
              {isEdit || isDelete || isDownload || isCancel || isView ? (
                <th style={{ ...headerStyle }}>Action</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody style={bodyStyle}>
            {data &&
              data.map((row, index) => (
                <tr className={`${hover && "hover"} ${striped && "striped"}`}>
                  {columns.map((col, index) => (
                    <td style={cellStyle} key={index}>
                      {col.cell ? col.cell(row) : row[col.field]}
                    </td>
                  ))}
                  {isEdit || isDelete || isDownload || isCancel || isView ? (
                    <td style={cellStyle}>
                      {isEdit ? (
                        <span
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => onEdit(row, index)}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <MdModeEditOutline color="#A21094" />
                        </span>
                      ) : null}

                      {isDelete ? (
                        <span
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => onDel(row, index)}
                          className="svg-icon svg-icon-md svg-icon-primary "
                        >
                          <AiOutlineDelete color="#A21094" />
                        </span>
                      ) : null}
                      {isDownload ? (
                        <span
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => onDown(row, index)}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <AiOutlineDownload color="#A21094" />
                        </span>
                      ) : null}

                      {isCancel ? (
                        <span
                          style={{ cursor: "pointer", marginRight: "0px" }}
                          // className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <ImCancelCircle color="#A21094" />
                        </span>
                      ) : null}

                      {isView ? (
                        <span
                          style={{ cursor: "pointer", marginRight: "10px" }}
                          onClick={() => onView(row, index)}
                          className="svg-icon svg-icon-md svg-icon-primary"
                        >
                          <MdVisibility color="#A21094" />
                        </span>
                      ) : null}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            {/* Display grand total row for columns with total set to true */}
            {hasTotalColumn && (
              <tr>
                {console.log(columns,"---columns")}
                {columns.map((col, index) => (
                  <td style={cellStyle} key={index}>
                    {col.total ? (
                      <strong>{calculateGrandTotal(data, col.field)}</strong>
                    ) : (
                      ""
                    )}
                  </td> 
                ))}
                {isEdit || isDelete || isDownload || isCancel || isView ? (
                  <td style={cellStyle}>
                    <strong>TOTAL</strong>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            )}
            {data ? null : (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                No Data Found
              </div>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CustomTable;

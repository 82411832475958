import { takeLatest, put } from 'redux-saga/effects';
import CONST from './Const';
import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import {
  //  AppInstall,
  // AppFilterDomain,
  ApiUrl,
} from '../helpers/Endpoints';
import downloadFile from '../../Utils/downloadFile';

const AppDashboard = process.env.REACT_APP_DASHBOARD_APP;
const AppDashboardDev = process.env.REACT_APP_DASHBOARD_APP + '/';
const AppFilterDomain = process.env.REACT_APP_FILTERS_APP;
const AppFilterDomainTest = process.env.REACT_APP_FILTERS_APP;
const IncentDetails = process.env.REACT_APP_DOMAIN;
const PublisherSummary = process.env.REACT_APP_FILTERS_APP;
/**
 * Testing domain
 * Will be removed in future
 */
const DOWNLOAD_DOMAIN = process.env.REACT_APP_DASHBOARD_APP;
const TEST_DOWNLOAD_BI2 = process.env.REACT_APP_FILTERS_APP;

const DASHBOARD_TYPE = {
  install: 'INSTALL',
  event: 'EVENT',
  reengagement_click: 'RE_CLICK',
  reengagement_event: 'RE_EVENT',
  click_impression: 'CLICK_IMPRESSION',
};

const Endpoint = {
  Filters: {
    PUBLISHER: '/publisher_list',
    EVENT_TYPE: '/event_list',
    AGENCY: '/vendor_list',
    // EVENT_TYPE: '/fraud_sub_category',
    // SUB_PUBLISHER: '/sub_publisher',
  },
  INSTALL: {
    TOTAL_BY_DATE: '/RPT_Total_Installs_latest',
    TOTAL_COUNT: '/RPT_Total_Installs_percentage_latest',
    TOP_CLEAN_PUBLISHER: '/RPT_Clean_Traffic_latest',
    TOP_FRAUD_PUBLISHER: '/RPT_Contributing_Fraud_latest',
    FRAUD_CATEGORY: '/RPT_Device_Fraud_Display_latest',
    EVENTS_FRAUD_BY_DATE: '/RPT_Install_Fraud_Date_count_latest',
    EVENTS_FRAUD_BY_WEEK: '/RPT_Total_Installs_Week_Wise_latest',
    EVENTS_FRAUD_BY_MONTH: '/RPT_Total_Installs_Month_Wise_latest',
    EVENTS_FRAUD_BY_INSTALL_TYPE:
      '/RPT_Total_Installs_organic_inorganic_latest',
    EVENTS_FRAUD_BY_PUBLISHER: '/RPT_Install_Fraud_Publisher_count_latest',
    FRAUD_SUB_CATEGORY: '/RPT_Fraud_Display_latest',
    FRAUD_SUB_CATEGORY_PERCENT: '/RPT_Device_Fraud_Count_percentage_latest',
    SUB_FRAUD_BY_DATE: '/RPT_Device_Fraud_Date_latest',
    SUB_FRAUD_BY_PUBLISHER: '/RPT_Device_Fraud_Publisher_latest',
    SUB_PUBLISHER: {
      domain: AppDashboardDev,
      end: '/RPT_Install_Fraud_subpublisher',
      method: 'GET',
    },
    INSERT_360_OUTPUT: {
      domain: ApiUrl,
      end: '/api/v2/br/ack/upload-output?package_name=:id',
      method: 'FORM_DATA',
    },
  },
  CLICK_IMPRESSION: {
    TOTAL_BY_DATE: '/RPT_Total_Installs_latest',
    TOTAL_COUNT: '/RPT_Total_Installs_percentage_latest',
    TOP_CLEAN_PUBLISHER: '/RPT_Clean_Traffic_latest',
    TOP_FRAUD_PUBLISHER: '/RPT_Contributing_Fraud_latest',
    FRAUD_CATEGORY: '/RPT_Device_Fraud_Display_latest',
    EVENTS_FRAUD_BY_DATE: '/RPT_Install_Fraud_Date_count_latest',
    EVENTS_FRAUD_BY_WEEK: '/RPT_Total_Installs_Week_Wise_latest',
    EVENTS_FRAUD_BY_MONTH: '/RPT_Total_Installs_Month_Wise_latest',
    EVENTS_FRAUD_BY_INSTALL_TYPE:
      '/RPT_Total_Installs_organic_inorganic_latest',
    EVENTS_FRAUD_BY_PUBLISHER: '/RPT_Install_Fraud_Publisher_count_latest',
    FRAUD_SUB_CATEGORY: '/RPT_Fraud_Display_latest',
    FRAUD_SUB_CATEGORY_PERCENT: '/RPT_Device_Fraud_Count_percentage_latest',
    SUB_FRAUD_BY_DATE: '/RPT_Device_Fraud_Date_latest',
    SUB_FRAUD_BY_PUBLISHER: '/RPT_Device_Fraud_Publisher_latest',
    SUB_PUBLISHER: {
      domain: AppDashboardDev,
      end: '/RPT_Install_Fraud_subpublisher',
      method: 'GET',
    },
  },
  EVENT: {
    TOTAL_BY_DATE: '/RPT_Total_Installs_latest_event',
    TOTAL_COUNT: '/RPT_Total_Installs_percentage_latest_event',
    TOP_CLEAN_PUBLISHER: '/RPT_Clean_Traffic_latest_event',
    TOP_FRAUD_PUBLISHER: '/RPT_Contributing_Fraud_latest_event',
    FRAUD_CATEGORY: '/RPT_Device_Fraud_Display_latest_event',
    EVENTS_FRAUD_BY_DATE: '/RPT_Install_Fraud_Date_count_latest_event',
    EVENTS_FRAUD_BY_WEEK: '/RPT_Total_Installs_Week_Wise_latest_event',
    EVENTS_FRAUD_BY_MONTH: '/RPT_Total_Installs_Month_Wise_latest_event',
    EVENTS_FRAUD_BY_INSTALL_TYPE:
      '/RPT_Total_Installs_organic_inorganic_latest_event',
    EVENTS_FRAUD_BY_PUBLISHER:
      '/RPT_Install_Fraud_Publisher_count_latest_event',
    FRAUD_SUB_CATEGORY: '/RPT_Fraud_Display_latest_event',
    FRAUD_SUB_CATEGORY_PERCENT:
      '/RPT_Device_Fraud_Count_percentage_latest_event',
    SUB_PUBLISHER: {
      domain: AppDashboardDev,
      end: '/RPT_Event_Fraud_subpublisher',
      method: 'GET',
    },
    SUB_FRAUD_BY_DATE: '/RPT_Device_Fraud_Date_latest_event',
    SUB_FRAUD_BY_PUBLISHER: '/RPT_Device_Fraud_Publisher_latest_event',
    UNIQUE_EVENT_BY_DATE: '/unique_date_count',
    UNIQUE_EVENT_BY_PUBLISHER: '/unique_publisher_count',
    PRIMARY_ATTRIBUTION_BY_DATE: '/primary_attribution_date_count',
    PRIMARY_ATTRIBUTION_BY_PUBLISHER: '/primary_attribution_publisher_count',
    NON_PRIMARY_ATTRIBUTION_BY_DATE: '/primary_attribution_date_count',
    NON_PRIMARY_ATTRIBUTION_BY_PUBLISHER:
      '/primary_attribution_publisher_count',
    CURRENT_MONTH_AFFILIATION_BY_DATE: '/cur_month_date_count',
    CURRENT_MONTH_AFFILIATION_BY_PUBLISHER: '/cur_month_publisher_count',
  },
  RE_EVENT: {
    TOTAL_BY_DATE: '/RPT_re_event_Total_event_latest',
    TOTAL_COUNT: '/RPT_re_event_Total_event_percentage_latest',
    TOP_CLEAN_PUBLISHER: '/RPT_re_event_Clean_Traffic_latest',
    TOP_FRAUD_PUBLISHER: '/RPT_re_event_Contributing_Fraud_latest',
    FRAUD_CATEGORY: '/RPT_re_event_Device_Fraud_Display_latest',
    EVENTS_FRAUD_BY_DATE: '/RPT_re_event_Fraud_Date_count_latest',
    EVENTS_FRAUD_BY_WEEK: '/RPT_re_event_Total_event_Week_Wise_latest',
    EVENTS_FRAUD_BY_MONTH: '/RPT_re_event_Total_event_Month_Wise_latest',
    EVENTS_FRAUD_BY_INSTALL_TYPE:
      '/RPT_re_event_Total_event_organic_inorganic_latest',
    EVENTS_FRAUD_BY_PUBLISHER: '/RPT_re_event_Fraud_Publisher_count_latest',
    FRAUD_SUB_CATEGORY: '/RPT_re_event_Fraud_Display_latest',
    FRAUD_SUB_CATEGORY_PERCENT:
      '/RPT_re_event_Device_Fraud_Count_percentage_latest',
    SUB_PUBLISHER: {
      domain: AppDashboardDev,
      end: '/RPT_Reengagement_Event_Fraud_subpublisher',
      method: 'GET',
    },
    SUB_FRAUD_BY_DATE: '/RPT_re_event_Device_Fraud_Date_latest',
    SUB_FRAUD_BY_PUBLISHER: '/RPT_re_event_Device_Fraud_Publisher_latest',
  },
  RE_CLICK: {
    TOTAL_BY_DATE: '/RPT_re_click_Total_click_latest',
    TOTAL_COUNT: '/RPT_re_click_Total_click_percentage_latest',
    TOP_CLEAN_PUBLISHER: '/RPT_re_click_Clean_Traffic_latest',
    TOP_FRAUD_PUBLISHER: '/RPT_re_click_Contributing_Fraud_latest',
    FRAUD_CATEGORY: '/RPT_re_click_Device_Fraud_Display_latest',
    EVENTS_FRAUD_BY_DATE: '/RPT_re_click_Fraud_Date_count_latest',
    EVENTS_FRAUD_BY_WEEK: '/RPT_re_click_Total_click_Week_Wise_latest',
    EVENTS_FRAUD_BY_MONTH: '/RPT_re_click_Total_click_Month_Wise_latest',
    EVENTS_FRAUD_BY_INSTALL_TYPE:
      '/RPT_re_click_Total_click_organic_inorganic_latest',
    EVENTS_FRAUD_BY_PUBLISHER: '/RPT_re_click_Fraud_Publisher_count_latest',
    FRAUD_SUB_CATEGORY: '/RPT_re_click_Fraud_Display_latest',
    FRAUD_SUB_CATEGORY_PERCENT:
      '/RPT_re_click_Device_Fraud_Count_percentage_latest',
    SUB_PUBLISHER: {
      domain: AppDashboardDev,
      end: '/RPT_Reengagement_Click_Fraud_subpublisher',
      method: 'GET',
    },
    SUB_FRAUD_BY_DATE: '/RPT_re_click_Device_Fraud_Date_latest',
    SUB_FRAUD_BY_PUBLISHER: '/RPT_re_click_Device_Fraud_Publisher_latest',
  },
  PUBLISHER_REPORT: {
    GET_TABLE: '/api/web-bi/appfraud/publisherConfig',
    GET_ONE: '/api/web-bi/appfraud/publisherConfig/getOne',
    ADD: {
      domain: ApiUrl,
      end: '/api/web-bi/appfraud/publisherConfig',
      method: 'POST',
    },
    DEL: {
      domain: ApiUrl,
      end: '/api/web-bi/appfraud/publisherConfig',
      method: 'DELETE',
    },
    DROPDOWN: {
      VARIATION_INSTALL: '/api/web-bi/publisher_config/variations/Install',
      VARIATION_EVENT: '/api/web-bi/publisher_config/variations/Event',
      FRAUD_SUB_CATEGORY_INSTALL: {
        domain: AppFilterDomain,
        end: '/subfraud_list',
        method: 'GET',
      },
      FRAUD_SUB_CATEGORY_EVENT: {
        domain: AppFilterDomain,
        end: '/subfraud_list',
        method: 'GET',
      },
    },
  },
  WHITELIST: {
    GET_TABLE: '/api/web-bi/whitelist',
    ADD: {
      domain: ApiUrl,
      end: '/api/web-bi/whitelist',
      method: 'POST',
    },
    EDIT: {
      domain: ApiUrl,
      end: '/api/web-bi/whitelist',
      method: 'PUT',
    },
    DELETE: {
      domain: ApiUrl,
      end: '/api/web-bi/whitelist',
      method: 'DELETE',
    },
    DROPDOWN: {
      PUBLISHER: {
        domain: AppFilterDomain,
        end: '/publisher_list',
      },
      CAMPAIGN: {
        domain: AppFilterDomain,
        end: '/campaign_list',
      },
      SUBPUBLISHER: {
        domain: AppFilterDomain,
        end: '/sub_publisher',
      },
      FRAUD_CATEGORY: '/api/web-bi/whitelist/fraud_category',
      FIELDS: '/api/web-bi/whitelist/fields',
    },
  },
  INCENT_DETAILS: {
    GET_TABLE: '/api/web-bi/appfraud/incent_details',
    DOWNLOAD_TABLE: '/api/web-bi/appfraud/incent_details/download',
  },
  PUBLISHER_SUMMARY: {
    GET_TABLE: '/table',
    DOWNLOAD_TABLE: '/download_publisher_report',
  },
  ALERT_PAGE: {
    GET_TABLE: '/api/web-bi/appfraud/alert_config',
    POST_TABLE: {
      domain: ApiUrl,
      end: '/api/web-bi/appfraud/alert_config',
      method: 'POST',
    },
  },
  EVENT_REPORT: {
    GET_TABLE: '/api/web-bi/appfraud/report_config',
    GENERATE_REPORT: {
      domain: AppFilterDomainTest,
      end: '/reports',
      method: 'GET',
    },
  },
  INSTALL_REPORT: {
    GET_TABLE: '/api/web-bi/appfraud/report_config',
    GENERATE_REPORT: {
      domain: AppFilterDomainTest,
      end: '/reports',
      method: 'GET',
    },
  },
  DASHBOARD_DOWNLOAD: {
    TOP_CLEAN_PUBLISHER: '/RPT_Clean_Traffic_latest_download',
    TOP_FRAUD_PUBLISHER: '/RPT_Contributing_Fraud_latest_download',
    FRAUD_DATE: '/RPT_Fraud_Category_Date_download',
    FRAUD_PUBLISHER: '/RPT_Fraud_Category_Publisher_download',
    FRAUD_TOTAL_DATE: '/RPT_Fraud_Date_download',
    FRAUD_TOTAL_PUBLISHER: '/RPT_Fraud_Publisher_download',
    FRAUD_TOTAL_SUB_PUBLISHER: {
      domain: DOWNLOAD_DOMAIN,
      endpoint: '/sub_publisher_count_fraud_percent_download',
    },
    FRAUD_SUB_DATE: '/RPT_Fraud_SubCategory_Date_download',
    FRAUD_SUB_PUBLISHER: '/RPT_Fraud_SubCategory_Publisher_download',
    UNIQUE_EVENTS_DATE: '/unique_date_count_download',
    UNIQUE_EVENTS_PUBLISHER: '/unique_publisher_count_download',
    CURRENT_MONTH_DATE: '/cur_month_date_count_download',
    CURRENT_MONTH_PUBLISHER: '/cur_month_publisher_count_download',
    PRIMARY_ATTRIBUTION_DATE: '/primary_attribution_date_count_download',
    PRIMARY_ATTRIBUTION_PUBLISHER:
      '/primary_attribution_publisher_count_download',
    NON_PRIMARY_ATTRIBUTION_DATE: '/primary_attribution_date_count_download',
    NON_PRIMARY_ATTRIBUTION_PUBLISHER:
      '/primary_attribution_publisher_count_download',
  },
  META_CAMPAIGN_CONFIGURATION: {
    GET_DETAILS_TABLE: '/api/web-bi/appfraud/campaign-config',
    POST_DETAILS: {
      domain: ApiUrl,
      end: '/api/web-bi/appfraud/campaign-config',
      method: 'POST',
    },
  },
};

function getDashboardData(type) {
  return function*(payload) {
    try {
      if (!Endpoint[type][payload.id])
        throw Error(`Endpoint not defined, Type:${payload.type}`);
      const params = payload.query;
      const url = AppDashboard + Endpoint[type][payload.id];
      const response = yield API.get(url, { params });
      yield put({
        type: CONST.DASHBOARD[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: CONST.DASHBOARD[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

// function getClickImpressionDashboardData(type) {
//   return function*(payload) {
//     try {
//       if (!Endpoint[type][payload.id])
//         throw Error(`Endpoint not defined, Type:${payload.type}`);
//       const params = payload.query;
//       const url = AppDashboard + Endpoint[type][payload.id];
//       const response = yield API.get(url, { params });

//       yield put({
//         type: CONST.DASHBOARD[type][payload.id].SUCCESS,
//         id: payload.id,
//         data: response,
//       });
//     } catch (error) {
//       console.error(payload.id, error);
//       yield put({
//         type: CONST.DASHBOARD[type][payload.id].ERROR,
//         id: payload.id,
//         error,
//       });
//     }
//   };
// }

function getDashboardDataDev(type) {
  return function*(payload) {
    try {
      if (!Endpoint[type][payload.id])
        throw Error(`Endpoint not defined, Type:${payload.type}`);
      const params = payload.query;
      const url = AppDashboardDev + Endpoint[type][payload.id];
      const response = yield API.get(url, { params });

      yield put({
        type: CONST.DASHBOARD[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: CONST.DASHBOARD[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

function getReengagementDashboardData(type) {
  return function*(payload) {
    try {
      if (!Endpoint[type][payload.id])
        throw Error(`Endpoint not defined, Type:${payload.type}`);
      const params = payload.query;
      const url = AppDashboard + Endpoint[type][payload.id];
      const response = yield API.get(url, { params });

      yield put({
        type: CONST.DASHBOARD[type][payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: CONST.DASHBOARD[type][payload.id].ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export function* getFilterData(payload) {
  try {
    if (!Endpoint.Filters[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = AppFilterDomain + Endpoint.Filters[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.FILTER[payload.id]?.SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.DASHBOARD[payload.id]?.ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getPublisherReportData(payload) {
  try {
    if (!Endpoint.PUBLISHER_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.PUBLISHER_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.PUBLISHER_REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.PUBLISHER_REPORT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getIncentDetailData(payload) {
  try {
    if (!Endpoint.INCENT_DETAILS[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.INCENT_DETAILS[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.INCENT_DETAILS[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.INCENT_DETAILS[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getPublisherSummaryData(payload) {
  try {
    if (!Endpoint.PUBLISHER_SUMMARY[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = PublisherSummary + Endpoint.PUBLISHER_SUMMARY[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.PUBLISHER_SUMMARY[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.PUBLISHER_SUMMARY[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getAlertPageData(payload) {
  try {
    if (!Endpoint.ALERT_PAGE[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.ALERT_PAGE[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.ALERT_PAGE[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.ALERT_PAGE[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getAppEventReportPageData(payload) {
  try {
    if (!Endpoint.EVENT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.EVENT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.REPORT.EVENT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.REPORT.EVENT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getAppInstallReportPageData(payload) {
  try {
    if (!Endpoint.INSTALL_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.INSTALL_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.REPORT.INSTALL[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.REPORT.INSTALL[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}
export function* getmetacampaignconfiguration(payload) {
  try {
    if (!Endpoint.META_CAMPAIGN_CONFIGURATION[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = ApiUrl + Endpoint.META_CAMPAIGN_CONFIGURATION[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.META_CAMPAIGN_CONFIGURATION[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.META_CAMPAIGN_CONFIGURATION[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

//Response handleing thorough setupAxios
export function* getIncentDetaiDownload(payload) {
  try {
    if (!Endpoint.INCENT_DETAILS[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.INCENT_DETAILS[payload.id];
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    yield put({
      type: CONST.INCENT_DETAILS[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.INCENT_DETAILS[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getPublisherSummaryDownload(payload) {
  try {
    if (!Endpoint.PUBLISHER_SUMMARY[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = PublisherSummary + Endpoint.PUBLISHER_SUMMARY[payload.id];
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    yield put({
      type: CONST.PUBLISHER_SUMMARY[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.PUBLISHER_SUMMARY[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getAppFiltersData(payload) {
  try {
    if (!Endpoint.Filters[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = AppFilterDomain + Endpoint.Filters[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.FILTER[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.FILTER[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data, {
              params: payload.query,
            });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            const response = yield API.put(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'FORM_DATA': {
            const formData = new FormData();
            const id = payload.packagename;
            const putUrl = url.replace(':id', id);
            for (const [key, value] of Object.entries(payload.data)) {
              formData.append(key, value);
            }
            const response = yield API.post(putUrl, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            const response = yield API.delete(url, { params, data });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export function* getPublisherReportDropdownData(payload) {
  try {
    const params = payload.query;
    const endpoint = Endpoint.PUBLISHER_REPORT.DROPDOWN[payload.id];
    if (!endpoint) throw Error(`Endpoint not defined, Type:${payload.type}`);
    if (typeof endpoint === 'object') {
      const url = endpoint.domain + endpoint.end;
      switch (endpoint.method) {
        case 'GET': {
          const response = yield API.get(url, { params });
          yield put({
            type: CONST.PUBLISHER_REPORT.DROPDOWN[payload.id].SUCCESS,
            id: payload.id,
            data: response,
          });
          break;
        }
        case 'POST': {
          const response = yield API.post(url, { params });
          yield put({
            type: CONST.PUBLISHER_REPORT.DROPDOWN[payload.id].SUCCESS,
            id: payload.id,
            data: response,
          });
          break;
        }
        default: {
          const response = yield API.get(url, { params });
          yield put({
            type: CONST.PUBLISHER_REPORT.DROPDOWN[payload.id].SUCCESS,
            id: payload.id,
            data: response,
          });
        }
      }
    } else {
      const url = ApiUrl + endpoint;
      const response = yield API.get(url, { params });
      yield put({
        type: CONST.PUBLISHER_REPORT.DROPDOWN[payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    }
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.PUBLISHER_REPORT.DROPDOWN[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function* DashboardDownloadSaga(payload) {
  try {
    if (!Endpoint.DASHBOARD_DOWNLOAD[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    let url = '';
    if (typeof Endpoint.DASHBOARD_DOWNLOAD[payload.id] === 'object')
      url =
        Endpoint.DASHBOARD_DOWNLOAD[payload.id].domain +
        Endpoint.DASHBOARD_DOWNLOAD[payload.id].endpoint;
    else url = DOWNLOAD_DOMAIN + Endpoint.DASHBOARD_DOWNLOAD[payload.id];
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    downloadFile({
      data: response,
      filename:
        payload.query.app +
        '_' +
        payload.query.fromDate +
        '_To_' +
        payload.query.toDate +
        '_' +
        payload.id +
        '.csv',
      contentType: 'text/csv',
    });
  } catch (error) {
    console.error(payload.id, error);
  }
}

export default function* DashboardSaga() {
  yield takeLatest(
    CONST.META_CAMPAIGN_CONFIGURATION.POST_DETAILS.INIT,
    sagaGenerator(
      Endpoint.META_CAMPAIGN_CONFIGURATION.POST_DETAILS,
      CONST.META_CAMPAIGN_CONFIGURATION.POST_DETAILS
    )
  );
  yield takeLatest(
    CONST.META_CAMPAIGN_CONFIGURATION.GET_DETAILS_TABLE.INIT,
    getmetacampaignconfiguration
  );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.TOTAL_BY_DATE.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.TOTAL_COUNT.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.TOP_CLEAN_PUBLISHER.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.FRAUD_SUB_CATEGORY.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.SUB_FRAUD_BY_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.SUB_PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.INSTALL.SUB_PUBLISHER,
      CONST.DASHBOARD.INSTALL.SUB_PUBLISHER
    )
  );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.TOP_FRAUD_PUBLISHER.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.SUB_FRAUD_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.FRAUD_SUB_CATEGORY_PERCENT.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.FRAUD_CATEGORY.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_DATE.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_WEEK.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_MONTH.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  // yield takeLatest(
  //   CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_INSTALL_TYPE.INIT,
  //   getDashboardData(DASHBOARD_TYPE.install)
  // );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.TOTAL_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.TOTAL_COUNT.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.TOP_CLEAN_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.TOP_FRAUD_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.FRAUD_CATEGORY.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_WEEK.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_MONTH.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.EVENTS_FRAUD_BY_INSTALL_TYPE.INIT,
    getDashboardData(DASHBOARD_TYPE.install)
  );

  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.EVENTS_FRAUD_BY_WEEK.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.EVENTS_FRAUD_BY_MONTH.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.EVENTS_FRAUD_BY_INSTALL_TYPE.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_event)
  );

  yield takeLatest(
    CONST.DASHBOARD.EVENT.EVENTS_FRAUD_BY_WEEK.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.EVENTS_FRAUD_BY_MONTH.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.EVENTS_FRAUD_BY_INSTALL_TYPE.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );

  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.EVENTS_FRAUD_BY_WEEK.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.EVENTS_FRAUD_BY_MONTH.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.EVENTS_FRAUD_BY_INSTALL_TYPE.INIT,
    getDashboardData(DASHBOARD_TYPE.reengagement_click)
  );

  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.EVENTS_FRAUD_BY_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.click_impression)
  );
  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.FRAUD_SUB_CATEGORY.INIT,
    getDashboardData(DASHBOARD_TYPE.click_impression)
  );
  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.SUB_FRAUD_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.click_impression)
  );
  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.SUB_FRAUD_BY_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.click_impression)
  );
  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.FRAUD_SUB_CATEGORY_PERCENT.INIT,
    getDashboardData(DASHBOARD_TYPE.click_impression)
  );
  //
  yield takeLatest(
    CONST.DASHBOARD.CLICK_IMPRESSION.SUB_PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.CLICK_IMPRESSION.SUB_PUBLISHER,
      CONST.DASHBOARD.CLICK_IMPRESSION.SUB_PUBLISHER
    )
  );
  yield takeLatest(
    CONST.DASHBOARD.INSTALL.INSERT_360_OUTPUT.INIT,
    sagaGenerator(
      Endpoint.INSTALL.INSERT_360_OUTPUT,
      CONST.DASHBOARD.INSTALL.INSERT_360_OUTPUT
    )
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.SUB_PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.EVENT.SUB_PUBLISHER,
      CONST.DASHBOARD.EVENT.SUB_PUBLISHER
    )
  );

  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.SUB_PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.RE_CLICK.SUB_PUBLISHER,
      CONST.DASHBOARD.RE_CLICK.SUB_PUBLISHER
    )
  );

  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.SUB_PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.RE_EVENT.SUB_PUBLISHER,
      CONST.DASHBOARD.RE_EVENT.SUB_PUBLISHER
    )
  );

  // EVENTS
  yield takeLatest(
    CONST.DASHBOARD.EVENT.TOTAL_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.TOTAL_COUNT.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.TOP_CLEAN_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.TOP_FRAUD_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.FRAUD_CATEGORY.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.EVENTS_FRAUD_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.EVENTS_FRAUD_BY_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.FRAUD_SUB_CATEGORY.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.SUB_FRAUD_BY_DATE.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.SUB_FRAUD_BY_PUBLISHER.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.FRAUD_SUB_CATEGORY_PERCENT.INIT,
    getDashboardData(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.UNIQUE_EVENT_BY_DATE.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.UNIQUE_EVENT_BY_PUBLISHER.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.PRIMARY_ATTRIBUTION_BY_DATE.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.PRIMARY_ATTRIBUTION_BY_PUBLISHER.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.NON_PRIMARY_ATTRIBUTION_BY_DATE.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.NON_PRIMARY_ATTRIBUTION_BY_PUBLISHER.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.CURRENT_MONTH_AFFILIATION_BY_DATE.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );
  yield takeLatest(
    CONST.DASHBOARD.EVENT.CURRENT_MONTH_AFFILIATION_BY_PUBLISHER.INIT,
    getDashboardDataDev(DASHBOARD_TYPE.event)
  );

  // RE_CLICK
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.TOTAL_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.TOTAL_COUNT.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.TOP_CLEAN_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.TOP_FRAUD_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.FRAUD_CATEGORY.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.EVENTS_FRAUD_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.EVENTS_FRAUD_BY_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.FRAUD_SUB_CATEGORY.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.SUB_FRAUD_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.SUB_FRAUD_BY_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_CLICK.FRAUD_SUB_CATEGORY_PERCENT.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_click)
  );

  // RE_EVENT

  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.TOTAL_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.TOTAL_COUNT.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.TOP_CLEAN_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.TOP_FRAUD_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.FRAUD_CATEGORY.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.EVENTS_FRAUD_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.EVENTS_FRAUD_BY_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.FRAUD_SUB_CATEGORY.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.SUB_FRAUD_BY_DATE.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.SUB_FRAUD_BY_PUBLISHER.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  yield takeLatest(
    CONST.DASHBOARD.RE_EVENT.FRAUD_SUB_CATEGORY_PERCENT.INIT,
    getReengagementDashboardData(DASHBOARD_TYPE.reengagement_event)
  );
  //Incent Details
  yield takeLatest(
    CONST.INCENT_DETAILS.DOWNLOAD_TABLE.INIT,
    getIncentDetaiDownload
  );
  yield takeLatest(CONST.INCENT_DETAILS.GET_TABLE.INIT, getIncentDetailData);

  //Publisher Summary

  yield takeLatest(
    CONST.PUBLISHER_SUMMARY.DOWNLOAD_TABLE.INIT,
    getPublisherSummaryDownload
  );
  yield takeLatest(
    CONST.PUBLISHER_SUMMARY.GET_TABLE.INIT,
    getPublisherSummaryData
  );

  //Alert Page
  yield takeLatest(CONST.ALERT_PAGE.GET_TABLE.INIT, getAlertPageData);
  yield takeLatest(
    CONST.ALERT_PAGE.POST_TABLE.INIT,
    sagaGenerator(Endpoint.ALERT_PAGE.POST_TABLE, CONST.ALERT_PAGE.POST_TABLE)
  );

  //App Report Pages
  //event
  yield takeLatest(
    CONST.REPORT.EVENT.GET_TABLE.INIT,
    getAppEventReportPageData
  );
  yield takeLatest(
    CONST.REPORT.EVENT.GET_TABLE.REFETCH,
    getAppEventReportPageData
  );
  yield takeLatest(
    CONST.REPORT.EVENT.GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.EVENT_REPORT.GENERATE_REPORT,
      CONST.REPORT.EVENT.GENERATE_REPORT
    )
  );
  // Install
  yield takeLatest(
    CONST.REPORT.INSTALL.GET_TABLE.INIT,
    getAppInstallReportPageData
  );
  yield takeLatest(
    CONST.REPORT.INSTALL.GET_TABLE.REFETCH,
    getAppInstallReportPageData
  );
  yield takeLatest(
    CONST.REPORT.INSTALL.GENERATE_REPORT.INIT,
    sagaGenerator(
      Endpoint.INSTALL_REPORT.GENERATE_REPORT,
      CONST.REPORT.INSTALL.GENERATE_REPORT
    )
  );
  // REPORTS
  yield takeLatest(CONST.PUBLISHER_REPORT.GET_ONE.INIT, getPublisherReportData);
  yield takeLatest(
    CONST.PUBLISHER_REPORT.GET_TABLE.INIT,
    getPublisherReportData
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_INSTALL.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_INSTALL,
      CONST.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_INSTALL
    )
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_EVENT.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_EVENT,
      CONST.PUBLISHER_REPORT.DROPDOWN.FRAUD_SUB_CATEGORY_EVENT
    )
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.DROPDOWN.VARIATION_EVENT.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.DROPDOWN.VARIATION_EVENT,
      CONST.PUBLISHER_REPORT.DROPDOWN.VARIATION_EVENT
    )
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.DROPDOWN.VARIATION_INSTALL.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_REPORT.DROPDOWN.VARIATION_INSTALL,
      CONST.PUBLISHER_REPORT.DROPDOWN.VARIATION_INSTALL
    )
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.ADD.INIT,
    sagaGenerator(Endpoint.PUBLISHER_REPORT.ADD, CONST.PUBLISHER_REPORT.ADD)
  );
  yield takeLatest(
    CONST.PUBLISHER_REPORT.DEL.INIT,
    sagaGenerator(Endpoint.PUBLISHER_REPORT.DEL, CONST.PUBLISHER_REPORT.DEL)
  );
  // Filters
  yield takeLatest(CONST.FILTER.PUBLISHER.INIT, getAppFiltersData);
  yield takeLatest(CONST.FILTER.AGENCY.INIT, getAppFiltersData);
  yield takeLatest(CONST.FILTER.EVENT_TYPE.INIT, getAppFiltersData);
  yield takeLatest(
    CONST.WHITELIST.GET_TABLE.INIT,
    sagaGenerator(Endpoint.WHITELIST.GET_TABLE, CONST.WHITELIST.GET_TABLE)
  );
  yield takeLatest(
    CONST.WHITELIST.PUBLISHER.INIT,
    sagaGenerator(
      Endpoint.WHITELIST.DROPDOWN.PUBLISHER,
      CONST.WHITELIST.PUBLISHER
    )
  );
  yield takeLatest(
    CONST.WHITELIST.FRAUD_CATEGORY.INIT,
    sagaGenerator(
      Endpoint.WHITELIST.DROPDOWN.FRAUD_CATEGORY,
      CONST.WHITELIST.FRAUD_CATEGORY
    )
  );
  yield takeLatest(
    CONST.WHITELIST.FIELDS.INIT,
    sagaGenerator(Endpoint.WHITELIST.DROPDOWN.FIELDS, CONST.WHITELIST.FIELDS)
  );
  yield takeLatest(
    CONST.WHITELIST.CAMPAIGN.INIT,
    sagaGenerator(
      Endpoint.WHITELIST.DROPDOWN.CAMPAIGN,
      CONST.WHITELIST.CAMPAIGN
    )
  );
  yield takeLatest(
    CONST.WHITELIST.SUBPUBLISHER.INIT,
    sagaGenerator(
      Endpoint.WHITELIST.DROPDOWN.SUBPUBLISHER,
      CONST.WHITELIST.SUBPUBLISHER
    )
  );
  yield takeLatest(
    CONST.WHITELIST.ADD.INIT,
    sagaGenerator(Endpoint.WHITELIST.ADD, CONST.WHITELIST.ADD)
  );
  yield takeLatest(
    CONST.WHITELIST.EDIT.INIT,
    sagaGenerator(Endpoint.WHITELIST.EDIT, CONST.WHITELIST.EDIT)
  );
  yield takeLatest(
    CONST.WHITELIST.DELETE.INIT,
    sagaGenerator(Endpoint.WHITELIST.DELETE, CONST.WHITELIST.DELETE)
  );
  // APP_DASHBOARD_DOWNLOAD
  yield takeLatest(
    [
      CONST.DASHBOARD_DOWNLOAD.TOP_CLEAN_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.TOP_FRAUD_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_TOTAL_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_TOTAL_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_TOTAL_SUB_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_SUB_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.FRAUD_SUB_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.UNIQUE_EVENTS_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.UNIQUE_EVENTS_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.CURRENT_MONTH_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.CURRENT_MONTH_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.PRIMARY_ATTRIBUTION_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.PRIMARY_ATTRIBUTION_PUBLISHER.INIT,
      CONST.DASHBOARD_DOWNLOAD.NON_PRIMARY_ATTRIBUTION_DATE.INIT,
      CONST.DASHBOARD_DOWNLOAD.NON_PRIMARY_ATTRIBUTION_PUBLISHER.INIT,
    ],
    DashboardDownloadSaga
  );
}

import { DNSLIST } from "../../Services/dns";

export const uploadFile = async (imageDetails) => {
  const myHeaders = new Headers();

  myHeaders.append(
    "content-disposition",
    `attachment; filename=\"${Date.now() + imageDetails?.name}\"`
  );

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: imageDetails,
    redirect: "follow",
  };

  return await fetch(DNSLIST.BRANDHYGENE.BASE_URL, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result?.signedURL;
    })
    .catch((error) => console.error(error));
};

export const scanFile = async (presignedUrl , endpoint = "generic_all") => {
  let body = {
    endpoint: endpoint,
    authorization: DNSLIST.BRANDHYGENE.AUTHORIZATION,
    s3_presigned_url: presignedUrl,
  };

  return await fetch(DNSLIST.BRANDHYGENE.SAFETY, {
    Accept: "*/*",
    "Content-Type": "application/json",
    method: "POST",
    body: JSON.stringify(body),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

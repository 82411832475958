/**
 * Entry application component used to compose providers and render Routes.
 * */
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// import { AuthInit } from "./modules/Auth";
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import store from '../redux/stores';
import DateProvider from './views/app/shared-componets/DatePicker/context';
import Session from '../_metronic/utils/Session';
import HiddenMenu from '../Components/Common/HiddenMenu';

// export default function App({ store, persistor, basename }) {
export default function App({ basename }) {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <Session />
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          <HiddenMenu />
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              {/* Render routes with provided `Layout`. */}
              {/* <AuthInit> */}
              <DateProvider>
                <Routes />
              </DateProvider>
              {/* </AuthInit> */}
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </React.Suspense>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default function FilterValue(selected = {}) {
  if (
    selected?.searchBack &&
    Array.isArray(selected?.searchQuery) &&
    selected?.searchQuery.length > 0
  )
    return selected?.data.join(',');
  else if (selected?.data?.length === 0) return 'all';
  else if (selected?.data?.length === selected.length) return 'all';
  else return selected?.data.join(',');
}

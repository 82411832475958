
//mFilterIt
export const companyDetails = {
  logo: "https://infringementportalcontent.mfilterit.com/images/media/logos/mfilterit-white-logo.png",
  company: "mFilterIt",
  description: ["mF Sentinel+ provides cutting-edge solutions to detect brand infringement instances, phishing scams and transaction-related fraud to ensure holistic protection against ever-evolving digital threats.",
      "mF Effcent provides robust protection against unscrupulous activities conducted by affiliates and influencer's using a brand’s assets and reduces the risk of brand guidelines violation.",
      "mF Tickr provides automated compliance regulation across creative and content to reduce errors and maximize efficiency with brand-compliant creative assets.",
      "mF Pace provides best-in-class brand reputation protection by identifying brand-unsafe content, reducing brand abuse and maximizing visibility and trust.",
      "mF Valid8, provides optimum protection from the ever-evolving threats of ad fraud, from impressions to events, ensuring your ads are seen by only your intended audience in a geo-targeted and brand-safe environment."
    ],
  about: "https://www.mfilterit.com/about-us/",
  contact: "https://www.mfilterit.com/contact-us/",
  website: "https://www.mfilterit.com/"
};


//Other
// export const companyDetails = {
//   logo: "https://www.madisonindia.com/assets/images/common/madison-world-july-2023.png",
//   company: "Madison Media",
//   description: ["test"],
//   about: "https://www.madisonindia.com/our-work.aspx",
//   contact: "https://www.madisonindia.com/contact-us.aspx",
//   website: "https://www.madisonindia.com/"
// };
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { checkIsActive } from '../../../_metronic/_helpers';
import { useSelector } from 'react-redux';

import './menu-icons.scss';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    const isActive = checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
    return isActive;
  };

  const menuList = useSelector(state => state?.common?.menu_list?.menus);

  let appRouteName = '';

  const category = new Map();
  const menu_icon = new Map();
  const sub_menu_icon = new Map();

  menuList &&
    menuList.forEach(e => {
      const subcategory = new Map(category.get(e.category) || []);
      const menuicons = new Map(menu_icon.get(e.category_icon) || []);
      const submenuicons = new Map(menu_icon.get(e.sub_category_icon) || []);

      if (subcategory.get(e.sub_category)) {
        subcategory.set(e.sub_category, [
          ...subcategory.get(e.sub_category),
          e,
        ]);
      } else {
        subcategory.set(e.sub_category, [e]);
      }
      if (e.category) {
        category.set(e.category, subcategory);
        menu_icon.set(e.category_icon, menuicons);
      } else {
        category.set(e.menu_title, e);
        menu_icon.set(e.menu_icon, menuicons);
      }
      sub_menu_icon.set(e.sub_category_icon, submenuicons);
    });

  const titles = Object.keys(Object.fromEntries(category));
  const menu_icons = Object.keys(Object.fromEntries(menu_icon));

  const finalJson = [];
  titles.forEach((e, i) => {
    const t_object = { title: e, menu_icon: menu_icons };
    t_object.subcategory = [];

    if (category.get(e) instanceof Map)
      Object.keys(Object.fromEntries(category.get(e))).forEach(f => {
        t_object.subcategory.push({ title: f, value: category.get(e).get(f) });
      });
    else {
      t_object.menu_icon[i] = category.get(e).menu_icon;
      t_object.route = category.get(e).route;
    }
    finalJson.push(t_object);
  });

  return (
    <ul className={`menu-nav ${layoutProps.ulClasses}`}>
      {finalJson &&
        finalJson?.map((v, i) => (
          <li
            key={i}
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              appRouteName,
              true
            )}`}
            aria-haspopup='true'
            data-menu-toggle='hover'>
            {/* /error route does not makes any sense, but do not remove */}
            <NavLink className='menu-link menu-toggle' to={v.route ?? '/error'}>
              <span className='svg-icon menu-icon'>
                <i className={`${v?.menu_icon[i]} menu-icon`}></i>
              </span>
              <span className='menu-text'>{v.title}</span>
              {!v.route && <i className='menu-arrow' />}
            </NavLink>
            {v?.subcategory?.map((v, i) => (
              <div key={i} className='menu-submenu'>
                <i className='menu-arrow' />
                <ul className='menu-subnav'>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      appRouteName,
                      true
                    )}`}
                    aria-haspopup='true'>
                    {v.title !== '' && v?.value ? (
                      <>
                        <NavLink className='menu-link menu-toggle' to='/error'>
                          <span className='svg-icon menu-icon'>
                            <i
                              className={`${
                                v?.value[0]?.sub_category === ''
                                  ? v?.value[0]?.menu_icon
                                  : v?.value[0]?.sub_category_icon
                              } menu-icon`}></i>
                          </span>

                          <span className='menu-text'>{v.title}</span>
                          <i className='menu-arrow' />
                        </NavLink>
                        <div className='menu-submenu'>
                          <i className='menu-arrow' />
                          <ul className='menu-subnav'>
                            {v?.value?.map((v, i) => (
                              <li
                                key={i}
                                className={`menu-item ${getMenuItemActive(
                                  v?.route
                                )}`}
                                aria-haspopup='true'>
                                <NavLink
                                  style={{ marginLeft: '20px' }}
                                  className='menu-link'
                                  to={v?.route}>
                                  <i className='menu-bullet menu-bullet-dot menu-icon'>
                                    <span />
                                  </i>
                                  <span className='menu-text'>
                                    {v.title === v?.sub_category
                                      ? v?.menu_title
                                      : v?.menu_title}
                                  </span>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ) : (
                      <ul>
                        {v.value?.map((v, i) => (
                          <li
                            key={i}
                            className={`menu-item ${getMenuItemActive(
                              v?.route
                            )}`}
                            aria-haspopup='true'>
                            <NavLink className='menu-link' to={v?.route}>
                              <span className='svg-icon menu-icon'>
                                <i
                                  className={`${
                                    v?.sub_category === ''
                                      ? v?.menu_icon
                                      : v?.sub_category_icon
                                  } menu-icon`}></i>
                              </span>

                              <span className='menu-text'>{v?.menu_title}</span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
            ))}
          </li>
        ))}
    </ul>
  );
}

export default AsideMenuList;

import { takeLatest } from 'redux-saga/effects';
import sagaGenerator from '../../../Utils/sagaGenerator';
import { PACKAGECONST, USER_MANAGEMENT, UPM } from './';

const defaultUrl = process.env.REACT_APP_DOMAIN;
const PACKAGE_ENDPOINT = {
  TABLE: '/api/config/package',
  ONE: {
    domain: defaultUrl,
    end: '/api/config/package',
    method: 'GET',
    param: true,
  },
  ADD: { domain: defaultUrl, end: '/api/config/package', method: 'POST' },
  EDIT: {
    domain: defaultUrl,
    end: '/api/config/package',
    method: 'PUT',
    param: true,
  },
  DELETE: {
    domain: defaultUrl,
    end: '/api/config/package',
    method: 'DELETE',
    param: true,
  },
};
const USER_ENDPOINT = {
  TABLE: '/api/user',
  ONE: {
    domain: defaultUrl,
    end: '/api/user/getone',
    method: 'GET',
    param: true,
  },
  ADD: { domain: defaultUrl, end: '/api/user/signup', method: 'POST' },
  EDIT: {
    domain: defaultUrl,
    end: '/api/user',
    method: 'PUT',
    param: true,
  },
  DELETE: {
    domain: defaultUrl,
    end: '/api/user',
    method: 'DELETE',
    param: true,
  },
};
const USER_PACKAGE_ENDPOINT = {
  TABLE: '/api/user/UPM',
  ONE: {
    domain: defaultUrl,
    end: '/api/user/UPM',
    method: 'GET',
    param: true,
  },
  ADD: { domain: defaultUrl, end: '/api/user/UPM', method: 'POST' },
  EDIT: {
    domain: defaultUrl,
    end: '/api/user/UPM',
    method: 'PUT',
    param: true,
  },
  DELETE: {
    domain: defaultUrl,
    end: '/api/user/UPM',
    method: 'DELETE',
    param: true,
  },
  // Dropdown
  USERS: '/api/user/all',
  MENUS: '/api/config/menu',
  PACKAGES: `/api/config/package/all`,
};

export function* UserManagementSaga() {
  yield takeLatest(
    PACKAGECONST.TABLE.INIT,
    sagaGenerator(PACKAGE_ENDPOINT.TABLE, PACKAGECONST.TABLE, { defaultUrl })
  );
  yield takeLatest(
    PACKAGECONST.ONE.INIT,
    sagaGenerator(PACKAGE_ENDPOINT.ONE, PACKAGECONST.ONE, { defaultUrl })
  );
  yield takeLatest(
    PACKAGECONST.ADD.INIT,
    sagaGenerator(PACKAGE_ENDPOINT.ADD, PACKAGECONST.ADD, { defaultUrl })
  );
  yield takeLatest(
    PACKAGECONST.EDIT.INIT,
    sagaGenerator(PACKAGE_ENDPOINT.EDIT, PACKAGECONST.EDIT, { defaultUrl })
  );
  yield takeLatest(
    PACKAGECONST.DELETE.INIT,
    sagaGenerator(PACKAGE_ENDPOINT.DELETE, PACKAGECONST.DELETE, { defaultUrl })
  );
  yield takeLatest(
    USER_MANAGEMENT.TABLE.INIT,
    sagaGenerator(USER_ENDPOINT.TABLE, USER_MANAGEMENT.TABLE, { defaultUrl })
  );
  yield takeLatest(
    USER_MANAGEMENT.ADD.INIT,
    sagaGenerator(USER_ENDPOINT.ADD, USER_MANAGEMENT.ADD, { defaultUrl })
  );
  yield takeLatest(
    USER_MANAGEMENT.ONE.INIT,
    sagaGenerator(USER_ENDPOINT.ONE, USER_MANAGEMENT.ONE, { defaultUrl })
  );
  yield takeLatest(
    USER_MANAGEMENT.EDIT.INIT,
    sagaGenerator(USER_ENDPOINT.EDIT, USER_MANAGEMENT.EDIT, { defaultUrl })
  );
  yield takeLatest(
    USER_MANAGEMENT.DELETE.INIT,
    sagaGenerator(USER_ENDPOINT.DELETE, USER_MANAGEMENT.DELETE, { defaultUrl })
  );
  // Dropdown: start
  yield takeLatest(
    UPM.USERS.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.USERS, UPM.USERS, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.PACKAGES.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.PACKAGES, UPM.PACKAGES, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.MENUS.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.MENUS, UPM.MENUS, {
      defaultUrl,
    })
  );
  // Dropdown: end
  yield takeLatest(
    UPM.TABLE.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.TABLE, UPM.TABLE, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.ONE.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.ONE, UPM.ONE, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.ADD.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.ADD, UPM.ADD, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.EDIT.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.EDIT, UPM.EDIT, {
      defaultUrl,
    })
  );
  yield takeLatest(
    UPM.DELETE.INIT,
    sagaGenerator(USER_PACKAGE_ENDPOINT.DELETE, UPM.DELETE, {
      defaultUrl,
    })
  );
}

import { takeLatest, put } from 'redux-saga/effects';
import CONST from './Const';
import { API, API_DOWNLOAD_CALL } from '../setupAxios';
import toast, { Toaster } from 'react-hot-toast';
import {
  //  AppInstall,
  // AppFilterDomain,
  ApiUrl,
} from '../helpers/Endpoints';

const AppDashboard = process.env.REACT_APP_DASHBOARD_APP;
const AppFilterDomain = process.env.REACT_APP_FILTERS_APP;
const IncentDetails = process.env.REACT_APP_DOMAIN;
const PublisherSummary = process.env.REACT_APP_FILTERS_APP;

const Endpoint = {
  PAYMENT_CONFIG: {
    GET_TABLE: '/api/reconciliation/payment',
    POST_TABLE: {
      domain: ApiUrl,
      end: '/api/reconciliation/payment',
      method: 'POST',
    },
    DELETE: {
      domain: ApiUrl,
      end: '/api/reconciliation/payment',
      method: 'DELETE',
    },
    EDIT: {
      domain: ApiUrl,
      end: '/api/reconciliation/payment',
      method: 'PUT',
    },
  },
  PUBLISHER_CONFIGURATION: {
    GET_TABLE: '/api/reconciliation/publisher_payment',
    PAYMENT_MODAL_ID: '/api/reconciliation/payment/all',
    POST_TABLE: {
      domain: ApiUrl,
      end: '/api/reconciliation/publisher_payment',
      method: 'POST',
    },
    DELETE: {
      domain: ApiUrl,
      end: '/api/reconciliation/publisher_payment',
      method: 'DELETE',
    },
    EDIT: {
      domain: ApiUrl,
      end: '/api/reconciliation/publisher_payment',
      method: 'PUT',
    },
  },
  INVOICE_PERFORMA: {
    GET_TABLE: '/api/reconciliation/settlement',
    POST_TABLE: {
      domain: ApiUrl,
      end: '/api/reconciliation/settlement',
      method: 'POST',
    },
    DELETE: {
      domain: ApiUrl,
      end: '/api/reconciliation/settlement',
      method: 'DELETE',
    },
    EDIT: {
      domain: ApiUrl,
      end: '/api/reconciliation/settlement',
      method: 'PUT',
    },
  },
  REPORT: {
    GET_TABLE: {
      domain: process.env.REACT_APP_FILTERS_APP,
      end: '/payout_report_v2',
      method: 'GET',
    },
    // GET_TABLE: '/api/reconciliation/payout_report_v2',
    INVOICE_GENRATE: '/api/reconciliation/generate_invoice',
  },
  REATTRIBUTION_EVENT_REPORT: {
    GET_TABLE: '/api/reconciliation/reattribution',
    // GENERATE_REPORT: {
    //   domain: IncentDetails,
    //   end: "/reports",
    //   method: "POST_QUERY",
    // },
  },
  REATTRIBUTION_INSTALL_REPORT: {
    GET_TABLE: '/api/reconciliation/reattribution',
    // GENERATE_REPORT: {
    //   domain: IncentDetails,
    //   end: "/reports",
    //   method: "POST_QUERY",
    // },
  },
  INVOICE_CONFIG: {
    GET_FORM: '/api/reconciliation/customer_info',
    POST_FORM: {
      domain: ApiUrl,
      end: '/api/reconciliation/customer_info',
      method: 'PUT_DATA',
    },
  },
};

export function* getReattributionEventData(payload) {
  try {
    if (!Endpoint.REATTRIBUTION_EVENT_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.REATTRIBUTION_EVENT_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.REATTRIBUTION_SUMMARY.EVENT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.REATTRIBUTION_SUMMARY.EVENT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}
export function* getReattributionInstallData(payload) {
  try {
    if (!Endpoint.REATTRIBUTION_INSTALL_REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url =
      IncentDetails + Endpoint.REATTRIBUTION_INSTALL_REPORT[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type:
        CONST.RECONCILATION.REATTRIBUTION_SUMMARY.INSTALL[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.REATTRIBUTION_SUMMARY.INSTALL[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getRecoPaymentConfigData(payload) {
  try {
    if (!Endpoint.PAYMENT_CONFIG[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.PAYMENT_CONFIG[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.PAYMENT_CONFIG[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.PAYMENT_CONFIG[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getRecoInvoiceConfigData(payload) {
  try {
    if (!Endpoint.INVOICE_CONFIG[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;

    const url = IncentDetails + Endpoint.INVOICE_CONFIG[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.INVOICE_CONFIG[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.INVOICE_CONFIG[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}
export function* getRecoPublisherConfigData(payload) {
  try {
    if (!Endpoint.PUBLISHER_CONFIGURATION[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.PUBLISHER_CONFIGURATION[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.PUBLISHER_CONFIGURATION[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.PUBLISHER_CONFIGURATION[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}
export function* getRecoPublisherPaymentIdData(payload) {
  try {
    if (!Endpoint.PUBLISHER_CONFIGURATION[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = { package_name: payload.query };
    const url = IncentDetails + Endpoint.PUBLISHER_CONFIGURATION[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.PUBLISHER_CONFIGURATION[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.PUBLISHER_CONFIGURATION[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}
export function* getRecoInvoicePerformaData(payload) {
  try {
    if (!Endpoint.INVOICE_PERFORMA[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.INVOICE_PERFORMA[payload.id];
    const response = yield API.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.INVOICE_PERFORMA[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.INVOICE_PERFORMA[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getRecoReportData(payload) {
  try {
    if (!Endpoint.REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    if (typeof Endpoint.REPORT[payload.id] === 'object') {
      const url =
        Endpoint.REPORT[payload.id].domain + Endpoint.REPORT[payload.id].end;
      switch (Endpoint.REPORT[payload.id].method) {
        case 'GET':
          const response = yield API.get(url, { params });
          yield put({
            type: CONST.RECONCILATION.REPORT[payload.id].SUCCESS,
            id: payload.id,
            data: response,
          });
          break;
        default:
          break;
      }
    } else {
      const url = IncentDetails + Endpoint.REPORT[payload.id];
      const response = yield API.get(url, { params });
      yield put({
        type: CONST.RECONCILATION.REPORT[payload.id].SUCCESS,
        id: payload.id,
        data: response,
      });
    }
  } catch (error) {
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.REPORT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

export function* getReportTableDownload(payload) {
  try {
    if (!Endpoint.REPORT[payload.id])
      throw Error(`Endpoint not defined, Type:${payload.type}`);
    const params = payload.query;
    const url = IncentDetails + Endpoint.REPORT[payload.id];
    const response = yield API_DOWNLOAD_CALL.get(url, { params });
    yield put({
      type: CONST.RECONCILATION.REPORT[payload.id].SUCCESS,
      id: payload.id,
      data: response,
    });
  } catch (error) {
    if (error?.response) {
      const msg = error?.response?.data?.message ?? 'Something went wrong';
      toast.error(msg);
    }
    console.error(payload.id, error);
    yield put({
      type: CONST.RECONCILATION.REPORT[payload.id].ERROR,
      id: payload.id,
      error,
    });
  }
}

function sagaGenerator(endpoint, constant) {
  if (!endpoint) throw Error(`Endpoint not defined`);
  if (!constant) throw Error(`Constant not defined`);
  return function*(payload) {
    console.log(endpoint, constant, payload);
    try {
      const params = payload.query;
      if (typeof endpoint === 'object') {
        if (!endpoint.end && !endpoint.domain)
          throw Error('Missing parameters in Endpoint.');
        const url = endpoint.domain + endpoint.end;
        switch (endpoint.method) {
          case 'GET': {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST': {
            const data = payload.data;
            const response = yield API.post(url, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'POST_QUERY': {
            const params = payload.query;
            const response = yield API.post(url, {}, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'PUT': {
            const data = payload.data;
            let editUrl = endpoint.domain + endpoint.end + '/' + payload.key;
            const response = yield API.put(editUrl, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'PUT_DATA': {
            console.log(payload, 'i am payload ');
            const data = payload.data;
            let editUrl = endpoint.domain + endpoint.end;
            const response = yield API.put(editUrl, data, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          case 'DELETE': {
            const data = payload.data;
            let Delurl = endpoint.domain + endpoint.end + '/' + data;
            const response = yield API.delete(Delurl, data);
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
            break;
          }
          default: {
            const response = yield API.get(url, { params });
            yield put({
              type: constant.SUCCESS,
              id: payload.id,
              data: response,
            });
          }
        }
      } else {
        const url = ApiUrl + endpoint;
        const response = yield API.get(url, { params });
        yield put({
          type: constant.SUCCESS,
          id: payload.id,
          data: response,
        });
      }
    } catch (error) {
      console.error(payload.id, error);
      yield put({
        type: constant.ERROR,
        id: payload.id,
        error,
      });
    }
  };
}

export default function* RecoSaga() {
  //payment config
  yield takeLatest(
    CONST.RECONCILATION.PAYMENT_CONFIG.GET_TABLE.INIT,
    getRecoPaymentConfigData
  );
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_CONFIG.GET_FORM.INIT,
    getRecoInvoiceConfigData
  );
  yield takeLatest(
    CONST.RECONCILATION.PAYMENT_CONFIG.DELETE.INIT,
    sagaGenerator(
      Endpoint.PAYMENT_CONFIG.DELETE,
      CONST.RECONCILATION.PAYMENT_CONFIG.DELETE
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.PAYMENT_CONFIG.EDIT.INIT,
    sagaGenerator(
      Endpoint.PAYMENT_CONFIG.EDIT,
      CONST.RECONCILATION.PAYMENT_CONFIG.EDIT
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.PAYMENT_CONFIG.POST_TABLE.INIT,
    sagaGenerator(
      Endpoint.PAYMENT_CONFIG.POST_TABLE,
      CONST.RECONCILATION.PAYMENT_CONFIG.POST_TABLE
    )
  );
  //publisher configuration
  yield takeLatest(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION.GET_TABLE.INIT,
    getRecoPublisherConfigData
  );
  yield takeLatest(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION.PAYMENT_MODAL_ID.INIT,
    getRecoPublisherPaymentIdData
  );
  yield takeLatest(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION.DELETE.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_CONFIGURATION.DELETE,
      CONST.RECONCILATION.PUBLISHER_CONFIGURATION.DELETE
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION.EDIT.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_CONFIGURATION.EDIT,
      CONST.RECONCILATION.PUBLISHER_CONFIGURATION.EDIT
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.PUBLISHER_CONFIGURATION.POST_TABLE.INIT,
    sagaGenerator(
      Endpoint.PUBLISHER_CONFIGURATION.POST_TABLE,
      CONST.RECONCILATION.PUBLISHER_CONFIGURATION.POST_TABLE
    )
  );
  //invoice performa
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_PERFORMA.GET_TABLE.INIT,
    getRecoInvoicePerformaData
  );
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_PERFORMA.DELETE.INIT,
    sagaGenerator(
      Endpoint.INVOICE_PERFORMA.DELETE,
      CONST.RECONCILATION.INVOICE_PERFORMA.DELETE
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_CONFIG.POST_FORM.INIT,
    sagaGenerator(
      Endpoint.INVOICE_CONFIG.POST_FORM,
      CONST.RECONCILATION.INVOICE_CONFIG.POST_FORM
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_PERFORMA.POST_TABLE.INIT,
    sagaGenerator(
      Endpoint.INVOICE_PERFORMA.POST_TABLE,
      CONST.RECONCILATION.INVOICE_PERFORMA.POST_TABLE
    )
  );
  yield takeLatest(
    CONST.RECONCILATION.INVOICE_PERFORMA.EDIT.INIT,
    sagaGenerator(
      Endpoint.INVOICE_PERFORMA.EDIT,
      CONST.RECONCILATION.INVOICE_PERFORMA.EDIT
    )
  );
  //report
  yield takeLatest(
    CONST.RECONCILATION.REPORT.GET_TABLE.INIT,
    getRecoReportData
  );
  yield takeLatest(
    CONST.RECONCILATION.REPORT.INVOICE_GENRATE.INIT,
    getReportTableDownload
  );
  // Reattribution Summary
  //event
  yield takeLatest(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.EVENT.GET_TABLE.INIT,
    getReattributionEventData
  );
  yield takeLatest(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.EVENT.GET_TABLE.REFETCH,
    getReattributionEventData
  );
  //install
  yield takeLatest(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.INSTALL.GET_TABLE.INIT,
    getReattributionInstallData
  );
  yield takeLatest(
    CONST.RECONCILATION.REATTRIBUTION_SUMMARY.INSTALL.GET_TABLE.REFETCH,
    getReattributionInstallData
  );
}

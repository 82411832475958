import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Card, Form,Container } from "react-bootstrap";
import SelectR from "react-select";
import * as yup from "yup";
import { Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import CustomTable from "../../app/views/app/TableComponent";
import {BrandRelevancy,RelevancyPost} from "../../redux/helpers/Endpoints";
import {numFormatter} from '../../app/utils';
import './style.css';


const QuickList = () => {
  const schema = yup.object().shape({
    location_code: yup.string().required("required"),
    keywords: yup.string().required("required"),
    language_code: yup.string().required("required"),
  });

  const [youtubeParams, setYoutubeParams] = useState([
    { location_code: "", keywords: "", language_code: "" },
  ]);
  const [selectedINput, setSelectedInput] = useState({});
  const initialValues = [{ location_code: "", keywords: "", language_code: "" }];
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [submitDisable,setSubmitDisable] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState({
    geolocation:true,
    keyword:true,
    language:true
  });
  const[loading,setLoading] = useState(false);
  const [limit,setLimit] = useState("");

  const [youtubeRelevancyResponse,setYoutubeRelevancyResponse] = useState([]);
  const token = localStorage.getItem("token");
  let config = {
    headers: {
      Accept: "*/*",
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  function addElement() {
    setIsInitialLoad({
      geolocation:true,
      keyword:true,
      language:true
    });

    let lastItem = youtubeParams[youtubeParams.length - 1];
    if (youtubeParams && !Object.keys(selectedINput).length) {
      toast.error("Please select geolocation from dropdown first.");
      return;
    }

    if (!lastItem.location_code) {
      toast.error("Please select geolocation");
      return;
    }

    if (youtubeParams && !lastItem.keywords) {
      toast.error("Please enter keyword");
      return;
    }
    if 
      (youtubeParams && !lastItem.language_code){
      toast.error("Please select language");
      return;
    }

    if (youtubeParams && Object.keys(selectedINput).length > 0) {
      setYoutubeParams([
        ...youtubeParams,
        { location_code: "", keywords: "", language_code: "" },
      ]);
      setSelectedInput({});
    }
    setRowCount(rowCount+1);
  }

  const removeElement = (id) => {
    
    setRowCount(rowCount-1);
    youtubeParams.splice(id, 1);
    setYoutubeParams([...youtubeParams]);
  };
useEffect(() =>{
  axios
    .get(
      `${BrandRelevancy}/country-codes?category=yt_relevancy`,
      config
    )
    .then((r) => {
      if (r?.data) {
        setCountries(r?.data?.data?.countries);
      }
    }).catch((error) => {
      console.log(error);
    })
} ,[]);


useEffect(() =>{
  axios
    .get(
      `${BrandRelevancy}/language-codes?category=yt_relevancy`,
      config
    )
    .then((r) => {
      if (r?.data) {
        setLanguages(r?.data?.data?.languages);
      }
    }).catch((error) => {
      console.log(error);
    })
} ,[]);

  async function addyoutubeRelevancy(){
    setYoutubeRelevancyResponse([]);
    setIsInitialLoad({
      geolocation:true,
      keyword:true,
      language:true
    });

    let lastItem = youtubeParams[youtubeParams.length - 1];
    if (youtubeParams && !Object.keys(selectedINput).length) {
      toast.error("Please select geolocation from dropdown first.");
      return;
    }

    if (!lastItem.location_code) {
      toast.error("Please select geolocation");
      return;
    }

    if (youtubeParams && !lastItem.keywords) {
      toast.error("Please enter keyword");
      return;
    }
    if 
      (youtubeParams && !lastItem.language_code){
      toast.error("Please select language");
      return;
    }

    setSubmitDisable(true);

    const transformPayload = () => {
      return youtubeParams.map(item => {
        const transformedItem = {
          location_code: item?.location_code?.value,
          language_code: item?.language_code?.value,
          keywords: item.keywords,
          is_user_registered : token ? "True" : "False"
        };
        return transformedItem;
      });
    };

    setYoutubeParams([
      { location_code: "", keywords: "", language_code: "" },
    ]);

    let transformedYoutubePayload = transformPayload();

    toast.success("Scanning initiated Please wait for some time...");
    setLoading(true);

    try {
      const response = await fetch(`${RelevancyPost}`,{ "Content-Type": "application/json", method: "POST",referrerPolicy: "no-referrer",body: JSON.stringify(transformedYoutubePayload) });
      const jsonData = await response.json();

      if(jsonData?.at(0)?.message){
        setYoutubeRelevancyResponse(jsonData);
        setLimit(jsonData?.at(0)?.message);
        toast.error(jsonData?.at(0)?.message);
        setSubmitDisable(false);
        setLoading(false);
        return;
      }
      setLoading(false);
  
      setYoutubeRelevancyResponse(jsonData);
    } catch (error) {
      toast.error("Something went wrong!");
    }
    setSubmitDisable(false);
  }

  return (
    <div className="PaymentContainer payment-model">
              <Row className="mr-0 ml-0">
        <Col sm={12} md={12} lg={12} xl={12} className="pl-0 pr-0">
          <div
            style={{
              backgroundColor: "white",
              height: "70px",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <h3>Quick List</h3>
          </div>
          </Col>
          </Row>
      <Card className=" gutter-b-custom" style={{height:"88vh"}}>
        <Toaster position="top-right" />
        <Card.Body className="pt-0 pb-0 InvoiceBody p-6 m-auto">
          <div className="border p-5">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
          >
            {({
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  {youtubeParams.map((item, index) => (
                  <div
                    style={{
                      width: "900px",
                      marginTop: "15px",
                    }}
                    className="d-flex"
                  >
                    <div className="d-flex flex-column">
                      <label htmlFor="">Geolocation</label>
                      <SelectR
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            boxShadow: state.isFocused && "2px solid #17c191",
                            border: state.isFocused && "2px solid #17c191",
                            minWidth: "225px",
                            "&:hover": {
                              border: "1px solid black",
                            },
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={countries}
                        onChange={(e) => {
                          setIsInitialLoad((prevState) => ({
                            ...prevState,
                            geolocation:false
                          }))
                          setSelectedInput(e);
                          let pg = [...youtubeParams];
                          pg[index]["location_code"] = e;
                          setYoutubeParams(pg);
                        }}
                        value={[...youtubeParams][index]["location_code"]}
                        menuPortalTarget={document.body}
                      />
                      {![...youtubeParams][index]["location_code"] && !isInitialLoad.geolocation && (
                        <div className="text-danger">Required</div>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="" style={{ marginLeft: "20px" }}>
                        Keyword
                      </label>
                      <TextField
                        style={{ paddingLeft: 20, width: "250px" }}
                        placeholder="Enter Value"
                        id={index}
                        value={item.keywords}
                        size="small"
                        onChange={(e) => {
                          setIsInitialLoad((prevState) => ({
                            ...prevState,
                            keyword:false
                          }))
                          let pg = [...youtubeParams];
                          pg[index]["keywords"] = e.target.value;
                          setYoutubeParams(pg);
                        }}
                        variant="outlined"
                      />
                              {![...youtubeParams][index]["keywords"] && !isInitialLoad.keyword && (
                          <div className="text-danger ml-7">Required</div>
                        ) }
                    </div>
                    <div className="d-flex flex-column">
                      <label htmlFor="" style={{ marginLeft: "23px" }}>
                        Language
                      </label>
                      <SelectR
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            boxShadow: state.isFocused && "2px solid #17c191",
                            border: state.isFocused && "2px solid #17c191",
                            minWidth: "225px",
                            marginLeft: "23px",
                            "&:hover": {
                              border: "1px solid black",
                            },
                            width: "250px",
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={languages}
                        onChange={(e) => {
                          setIsInitialLoad((prevState) => ({
                            ...prevState,
                            language:false
                          }))
                          setSelectedInput(e);
                          let pg = [...youtubeParams];
                          pg[index]["language_code"] = e;
                          setYoutubeParams(pg);
                        }}
                        value={[...youtubeParams][index]["language_code"]}
                        menuPortalTarget={document.body}
                      />
                         {![...youtubeParams][index]["language_code"] && !isInitialLoad.language && (
                        <div className="text-danger ml-7">Required</div>
                      )}
                    </div>
                    <>
                      {rowCount <= 0 ? (
                          <AddIcon
                            style={{
                              color: "#A21094",
                              marginLeft: 15,
                              cursor: "pointer",
                              minHeight: 35,
                              minWidth: 35,
                              marginTop: 24,
                            }}
                            onClick={() => addElement()}
                          />
                      ) : (
                        <>
                        <AddIcon
                            style={{
                              color: "#A21094",
                              marginLeft: 15,
                              cursor: "pointer",
                              minHeight: 35,
                              minWidth: 35,
                              marginTop: 24,
                            }}
                            onClick={() => addElement()}
                          />
                        <DeleteIcon
                          style={{
                            marginLeft: 20,
                            minHeight: 30,
                            minWidth: 30,
                            marginTop: 26,
                          }}
                          onClick={() => removeElement(index)}
                        />
                        </>
                      )}
                    </>
                  </div>))}
                </div>
              </Form>
            )}
          </Formik>
          <div className="close_button d-flex justify-content-center mt-6">

          {!submitDisable ? (<Button
              type="submit"
              style={{ backgroundColor: "#530093" }}
              onClick={addyoutubeRelevancy}
              disabled={submitDisable}
            >
              Submit
            </Button>): (<Button
              type="submit"
              style={{ backgroundColor: "#530093" }}
              onClick={addyoutubeRelevancy}
              disabled={submitDisable}
            >
              Scanning...
            </Button>)}
          </div>
          </div>
          {youtubeRelevancyResponse?.length === 1 ? <div className="d-flex align-items-center justify-content-center mt-6"><h4>{limit}</h4></div> :
          <Container style={{ marginTop: "20px",padding:"0px" }}>
           {youtubeRelevancyResponse?.length > 1 && <strong>Sample 20 videos out of potential list of {numFormatter(Math.floor(Math.random() * (100000 - 45000 + 1) + 45000))}</strong>}
            <div className="tableScroll">
              <CustomTable
                data={youtubeRelevancyResponse}
                loading={loading}
                columns={[
                  { field: "keyword", header: "Keyword" },
                  { field: "category", header: "Category" },
                  { field: "title", header: "Title" },
                  { field: "video_url", header: "URL" },
                  { field: "owner_channel_name", header: "Owner" },
                  { field: "view_count", header: "Views" },
                  { field: "length_seconds", header: "Length(Seconds)" },
                  { field: "upload_date", header: "Upload Date" },
                  { field: "status", header: "Status" },
                ]}
                hover={true}
                striped={true}
                headerStyle={{
                  textAlign: "center",
                  width: "80px",
                  minWidth: "100px",
                }}
                tableHeight="49vh"
                cellStyle={{
                  textAlign: "center",
                  wordBreak: "break-all",
                }}
              />
            </div>
          </Container>}
        </Card.Body>
      </Card>
    </div>
  );
};

export default QuickList;

import * as constant from '../constants/CommonConstants';
import { getLocalStorage } from '../../app/utils/helpers';
import { AUTH_DATA } from '../../app/utils/const';

const auth = JSON.parse(getLocalStorage(AUTH_DATA.AUTH));
export const FetchPackagename = () => {
  return {
    type: constant.PACKAGENAME_INIT,
  };
};

export const FetchMenulist = packageName => {
  return {
    type: constant.MENULIST_INIT,
    packageName,
  };
};

export const FetchFilterlist = packageName => {
  return {
    type: constant.FILTERLIST_INIT,
    packageName,
  };
};

export const FetchChannellist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CHANNEL_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchCountrylist = (packageName, fromDate, toDate) => {
  return {
    type: constant.COUNTRY_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchCategorylist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CATEGORY_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchBrandlist = (packageName, fromDate, toDate) => {
  return {
    type: constant.BRAND_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchPrioritylist = (packageName, fromDate, toDate) => {
  return {
    type: constant.PRIORITY_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchPublisherlist = (packageName, fromDate, toDate) => {
  return {
    type: constant.PUBLISHER_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};
export const FetchStatuslist = (packageName, fromDate, toDate) => {
  return {
    type: constant.STATUS_LIST_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

export const FetchWebChannellist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_CHANNEL_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};
export const FetchWebPublisheridlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_PUBLISHER_ID_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};
export const FetchWebCampaignlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  campageNo,
  campsearch
) => {
  return {
    type: constant.WEB_CAMPAIGN_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
    campageNo,
    campsearch,
  };
};
export const FetchWebCreativelist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_CREATIVE_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};
export const FetchWebSystemDomainlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  pageNo,
  search
) => {
  return {
    type: constant.WEB_SYSTEM_DOMAIN_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
    pageNo,
    search,
  };
};
export const FetchWebPlacementidlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  page
) => {
  return {
    type: constant.WEB_PLACEMENT_ID_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
    page,
  };
};
export const FetchWebFraudCategorylist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_FRAUD_CATEGORY_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};
export const FetchWebFraudSubCategorylist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_FRAUD_SUB_CATEGORY_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};
export const FetchWebPlacementtypelist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_PLACEMENT_TYPE_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};

export const FetchDashboardtypelist = packageName => {
  return {
    type: constant.WEB_DASHBOARD_TYPE_LIST_INIT,
    packageName,
  };
};

// export const setDashboard_type = (selectedDashboardType) => {
//     console.log("setDashboardtype",selectedDashboardType)
//     return {
//         type: constant.WEB_SET_DASHBOARD_TYPE_LIST_INIT,
//         selectedDashboardType

//     }
// };
export const FetchWebPublisherlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_PUBLISHER_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};

export const FetchWebSubPublisherlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype
) => {
  return {
    type: constant.WEB_SUB_PUBLISHER_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
  };
};

export const FetchWebPageidlist = (
  packageName,
  fromDate,
  toDate,
  dashboardtype,
  pageNo,
  search
) => {
  return {
    type: constant.WEB_PAGE_ID_LIST_INIT,
    packageName,
    fromDate,
    toDate,
    dashboardtype,
    pageNo,
    search,
  };
};

//mtrackit
export const FetchCampaignlist = (package_name, fromDate, toDate) => {
  return {
    type: constant.CAMPAIGN_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchPublishername = (package_name, fromDate, toDate) => {
  return {
    type: constant.PUBLISHER_NAME_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchEventlist = (package_name, fromDate, toDate) => {
  return {
    type: constant.EVENT_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchFraudlist = (package_name, fromDate, toDate) => {
  return {
    type: constant.FRAUD_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

//click

export const FetchClickFraudCategorylist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CLICK_FRAUD_CATEGORY_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

export const FetchClickFraudSubCategorylist = (
  packageName,
  fromDate,
  toDate
) => {
  return {
    type: constant.CLICK_FRAUD_SUB_CATEGORY_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

export const FetchClickPublisherNamelist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CLICK_PUBLISHER_NAME_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

export const FetchClickCampaignIdlist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CLICK_CAMPAIGN_ID_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

export const FetchClickDeviceTypelist = (packageName, fromDate, toDate) => {
  return {
    type: constant.CLICK_DEVICE_TYPE_INIT,
    packageName,
    fromDate,
    toDate,
  };
};

//event

export const FetchEventCampaignlist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_CAMPAIGN_LIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};

export const FetchEventPagelist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_PAGE_LIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};

export const FetchEventPublisherlist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_PUBLISHER_LIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};

export const FetchEventChannellist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_CHANNEL_LIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};

export const FetchEventEventlist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_EVENT_LIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};
// event report list

export const fetchWebEventReport = (package_name, page, limit) => {
  return {
    type: constant.WEB_EVENT_REPORT_LIST_INIT,
    package_name,
    // page,
    // limit,
  };
};

//app event report

export const fetchAppEventReport = reportdata => {
  return {
    type: constant.APP_EVENT_REPORT_LIST_INIT,
    reportdata,
  };
};

//install reports

export const fetchInstallReport = reportdata => {
  return {
    type: constant.APP_INSTALL_REPORT_LIST_INIT,
    reportdata,
  };
};

// web event report filter drawer

export const FetchReportFeildslist = dashboard_type => {
  return {
    type: constant.EVENT_REPORT_FEILDS_lIST_INIT,
    dashboard_type,
  };
};

export const FetchReportPublisherlist = (
  package_name,
  fromDate,
  toDate,
  pageNo,
  search
) => {
  return {
    type: constant.EVENT_REPORT_PUBLISHER_lIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
    search,
  };
};

export const FetchReportFraudCategorylist = (
  package_name,
  fromDate,
  toDate,
  pageNo
) => {
  return {
    type: constant.EVENT_REPORT_FRAUD_CATEGORY_lIST_INIT,
    package_name,
    fromDate,
    toDate,
    pageNo,
  };
};
// genrate Report

export const FetchGenerateReport = generatedate => {
  return {
    type: constant.EVENT_GENERATE_REPORT_INIT,
    generatedate,
  };
};

export const FetchAppEventGenerateReport = generatedate => {
  return {
    type: constant.APP_EVENT_GENERATE_REPORT_INIT,
    generatedate,
  };
};

export const FetchAppInstallGenerateReport = generatedate => {
  return {
    type: constant.INSTALL_GENERATE_REPORT_INIT,
    generatedate,
  };
};

export const FetchImpressionIntegrityReport = generatedate => {
  return {
    type: constant.IMPRESSION_INTEGRITY_REPORT_INIT,
    generatedate,
  };
};

export const RefreshInstallReport = reportdata => {
  return {
    type: constant.APP_INSTALL_REPORT_REFETCH,
    reportdata,
  };
};

export const RefreshAppEventReport = reportdata => {
  return {
    type: constant.APP_EVENT_REPORT_REFETCH,
    reportdata,
  };
};

export const RefreshEventReport = package_name => {
  return {
    type: constant.WEB_EVENT_REPORT_REFETCH,
    package_name,
  };
};

export const AppFPublisherTest = {
  GetNext: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
    page = 1,
    limit = 500,
  }) => {
    return {
      type: constant.APP_F_PUBLISHER_TEST_NEXT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
        record_limit: limit,
        page_number: page,
      },
    };
  },
  Fetch: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
    page = 1,
    limit = 500,
  }) => {
    return {
      type: constant.APP_F_PUBLISHER_TEST_INIT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
        record_limit: limit,
        page_number: page,
      },
    };
  },
};
export const AppFPublisher = {
  GetNext: (end = 500) => ({ type: constant.APP_F_PUBLISHER_NEXT, end }),
  Fetch: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
  }) => {
    return {
      type: constant.APP_F_PUBLISHER_INIT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
      },
    };
  },
};
export const FetchAppFCountry = {
  GetNext: (end = 500) => ({ type: constant.APP_F_COUNTRY_NEXT, end }),
  FETCH: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
  }) => {
    return {
      type: constant.APP_F_COUNTRY_INIT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
      },
    };
  },
};

export const FetchAppFCampaign = {
  GetNext: (end = 500) => ({ type: constant.APP_F_CAMPAIGN_NEXT, end }),
  FETCH: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
  }) => {
    return {
      type: constant.APP_F_CAMPAIGN_INIT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
      },
    };
  },
};
export const FetchAppFVendor = ({
  package_name,
  fromDate,
  toDate,
  publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
  report_type,
  vendor_id = '',
  region = '',
  user_type = 'customer',
  sub_publisher_name = '',
  campaign_id = '',
  event_type = '',
  page_id = '',
  fraud_category = '',
  publisher_type = 'all',
  search = '',
  query = '',
}) => {
  return {
    type: constant.APP_F_VENDOR_INIT,
    payload: {
      package_name,
      start_date: fromDate,
      end_date: toDate,
      publisher_name,
      report_type,
      vendor_id,
      user_type,
      region,
      sub_publisher_name,
      campaign_id,
      event_type,
      page_id,
      fraud_category,
      publisher_type,
      search,
      query,
    },
  };
};

export const FetchAppFEvent = {
  GetNext: (end = 500) => ({ type: constant.APP_F_EVENT_NEXT, end }),
  FETCH: ({
    package_name,
    fromDate,
    toDate,
    publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
    report_type,
    vendor_id = '',
    region = '',
    user_type = 'customer',
    sub_publisher_name = '',
    campaign_id = '',
    event_type = '',
    page_id = '',
    fraud_category = '',
    search = '',
    publisher_type = 'all',
  }) => {
    return {
      type: constant.APP_F_EVENT_INIT,
      payload: {
        package_name,
        start_date: fromDate,
        end_date: toDate,
        publisher_name,
        report_type,
        vendor_id,
        user_type,
        region,
        sub_publisher_name,
        campaign_id,
        event_type,
        page_id,
        fraud_category,
        search,
        publisher_type,
      },
    };
  },
};
export const FetchAppFSubPublisher = ({
  package_name,
  fromDate,
  toDate,
  publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
  report_type,
  vendor_id = '',
  region = '',
  // user_type = 'customer',
  sub_publisher_name = '',
  campaign_id = '',
  event_type = '',
  page_id = '',
  fraud_category = '',
  search = '',
  publisher_type = 'all',
}) => {
  return {
    type: constant.APP_F_SUBPUBLISHER_INIT,
    payload: {
      package_name,
      start_date: fromDate,
      end_date: toDate,
      publisher_name,
      report_type,
      vendor_id,
      // user_type,
      region,
      sub_publisher_name,
      campaign_id,
      event_type,
      page_id,
      fraud_category,
      publisher_type,
    },
  };
};
export const FetchAppFFields = ({
  package_name,
  fromDate,
  toDate,
  publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
  report_type,
  vendor_id = '',
  region = '',
  user_type = 'customer',
  sub_publisher_name = '',
  campaign_id = '',
  event_type = '',
  page_id = '',
  fraud_category = '',
  report_variant = 'transactional',
}) => {
  return {
    type: constant.APP_F_FIELDS_INIT,
    payload: {
      package_name,
      start_date: fromDate,
      end_date: toDate,
      publisher_name,
      report_type,
      vendor_id,
      user_type,
      region,
      sub_publisher_name,
      campaign_id,
      event_type,
      page_id,
      fraud_category,
      report_variant,
    },
  };
};

export const FetchAppFFraud = ({
  package_name,
  fromDate,
  toDate,
  publisher_name = auth.role === 'publisher' ? auth?.publisher.join(',') : '',
  os_type = 'all',
  campaign_id = 'all',
  vendor_id = 'all',
  region = 'all',
  report_type = 'Install',
}) => {
  return {
    type: constant.APP_F_FRAUD_INIT,
    payload: {
      app: package_name,
      fromDate,
      toDate,
      publisher: publisher_name,
      os_type,
      campaign_id,
      vendor_id,
      region,
    },
    report_type,
  };
};

//mtrackit Report

export const FetchMtrackitReportCampaignlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_REPORT_CAMPAIGN_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitReportPublishername = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_REPORT_PUBLISHER_NAME_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitReportEventlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_REPORT_EVENT_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitReportFraudlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_REPORT_FRAUD_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

//mtrackit Report Campaign

export const FetchMtrackitCampaignReportCampaignlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_CAMPAIGN_REPORT_CAMPAIGN_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitCampaignReportPublisherlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_CAMPAIGN_REPORT_PUBLISHER_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

//mtrackit report Postback
export const FetchMtrackitPostbackReportEventlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_POSTBACK_REPORT_EVENT_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitPostbackReportPbStatuslist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_POSTBACK_REPORT_PB_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

export const FetchMtrackitPostbackReportPublisherlist = (
  package_name,
  fromDate,
  toDate
) => {
  return {
    type: constant.MTRACKIT_POSTBACK_REPORT_PUBLISHER_LIST_INIT,
    package_name,
    fromDate,
    toDate,
  };
};

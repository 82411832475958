import React from 'react';
import toast from 'react-hot-toast';
import { CloseButton } from 'react-bootstrap';

export const showToast = (errorMessage) => {
    toast.error(
      (t) => (
        <div className="d-flex">
          <span>{errorMessage}</span>
          <CloseButton
            onClick={() => toast.dismiss(t.id)}
            style={{ fontSize: "2rem" }}
          />
        </div>
      ),
      {
        duration: 3000,
      }
    );
};

export const checkSizeInMB = (file) => {
    let sizeInMB = (
      file.size / (1024 * 1024)
    ).toFixed(2)

    return sizeInMB;
}